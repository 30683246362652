import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { nonAuthenticatedRoutes } from 'routes/app.routes.js';
import PoweredBy from '../../components/logo/PoweredBy';
import Helper from '../../core/helper/helper';
import LiteVersionContainer from './LiteVersionContainer';
import { GENERAL } from '../../assets/images/images';
import { HelperSchedule } from '../../views/schedule/core/helpers/helperSchedule';
import SimpleNonAuthContainer from '../SimpleNonAuthContainer';

class NonAuthenticated extends React.Component {
  constructor(props) {
    super(props)
    this.isLiteVersion = Helper.isLiteVersion();
    this.isSchedule = HelperSchedule.isSchedulePage();
    this.isHelpDeleteAccount = Helper.isHelpDeleteAccount();
  }

  render() {
    if (this.isSchedule) {
      return <SimpleNonAuthContainer />
    }
    if (this.isLiteVersion) {
      return <LiteVersionContainer />
    }
    if (!this.isLiteVersion && !this.isSchedule) {
      return (
        <div className="container-body h-100" lang={"pt"}>
          <div className="container-view-contex no-header text-center w-100 px-3 pb-4 h-100"
            style={{ overflowY: 'auto' }}>
            <div className="company-logo-container mt-2 mt-md-4">
              <img
                className="kmclient-logo"
                src={GENERAL.logoKMclient}
                alt="logo"
                onClick={() => window.location.href = window.location.origin + '/' + window.location.pathname.split('/')[1]}
              />
            </div>
            <Switch>
              {
                nonAuthenticatedRoutes.map((prop, key) => {
                  if (prop.redirect) return (<Redirect from={prop.path} to={prop.to} key={key} />);
                  return (<Route path={prop.path} component={prop.component} key={key} />);
                })
              }
            </Switch>
            <div className="powered-by-container mt-4"> <PoweredBy /></div>
          </div>
        </div>
      );
    }
  }

}

export default NonAuthenticated;
