import React, { Fragment } from 'react';
import {
  Route, Switch, Redirect, withRouter,
} from 'react-router-dom';

import Header from 'components/header/header.jsx';
import Sidebar from 'components/sidebar/sidebar.jsx';

import { nonAuthenticatedRoutes } from 'routes/app.routes.js';
import PhoneCallButton from '../../components/button/PhoneCallButton';
import PlayStoreButton from '../../components/button/playstoreButton';
import WelcomeLogo from '../../components/animations/welcome-logo';
import Helper from '../../core/helper/helper';
import { toast } from "react-toastify";
import Emitter from '../../core/services';

class LiteVersionContainer extends React.Component {
  constructor() {
    super();
    const query = window.location.search;
    this.token = new URLSearchParams(query).get('token');
    this.token_cal = new URLSearchParams(query).get('token_cal'); 
    this.state = {
      showWelcome: !Helper.isFO(),
      showToast: !!(this.token || this.token_cal),
    }
  }
  componentDidMount() {
    Emitter.on('TOAST_LITEVERSION_DISABLE', () => {
      this.setState({ showToast: false });
      toast.dismiss();
    });

    const timeOutWelcomeAnimation = Helper.isFO() ? 0 : 4000; // Initial annimation
    setTimeout(() => { this.setState({ showWelcome: false }); }, timeOutWelcomeAnimation);

    const isFOTimeout = Helper.isFO() ? 2000 : 6000;
    this.openLiteVersionToast(isFOTimeout);

    this.unlisten = this.props.history.listen(() => {
      this.openLiteVersionToast(2500);
    });
  }

  componentWillUnmount() {
    Emitter.off('TOAST_LITEVERSION_DISABLE');
    this.unlisten();
  }

  openLiteVersionToast = (isFOTimeout = 0) => {
    if (!toast.isActive(Helper.toastLiteVersionOpenned)) {
      setTimeout(() => {
        if (!toast.isActive(Helper.toastLiteVersionOpenned) && this.state.showToast) {
          if (window.location.href.includes("services-lite")) {
            Helper.toastLiteVersionOpenned = toast(this.registerToastBody, Helper.toastOptionsBV);
          } else {
            if (!window.location.href.includes("schedule-lite")) {
              Helper.toastLiteVersionOpenned = toast(this.registerToastBody, Helper.toastOptions);
            }
          }
        }
      }, isFOTimeout);
    }
    const toastElem = document.querySelector(".Toastify__toast-container--top-right") || document.querySelector(".Toastify__toast-container--top-right_BV")
    if (toastElem) {
      if ((window.location.href.includes("services-lite")) && (window.innerWidth <= 768)) {
        toastElem.style.top = "109px";
      }
      if ((window.location.href.includes("service-details-lite")) && (window.innerWidth <= 768)) {
        toastElem.style.top = "60px";
        toastElem.style.position = "absolute";
      }
      if ((window.location.href.includes("service-details-lite")) && (window.innerWidth > 768)) {
        toastElem.style.top = "84px";
      }
    }
  }

  registerToastBody = () => {
    return (
      <Fragment>
        <div>
          <div>
            <span style={{ fontWeight: "700", fontSize: "16px", padding: "4px" }}>{Helper.isInternalTeamCompany() ? "O que vão ver os meus Clientes?" : "Estes dados são seus?"}</span>
          </div>
          <div style={{padding: "8px 2px 0px 4px"}}>
            <span className=""> Para ver a informação toda, </span>
            <span
              className="link-container ms-1"
              onClick={() => Helper.logoutFromLite()}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "inline-flex", fontSize: "16px" }}
            >
              clique aqui
            </span>
          </div>
        </div>

      </Fragment>
    );
  }

  render() {
    if (!this.state.showWelcome) {
      return (
        <div className="container-body" lang={"pt"}>
          <Sidebar />
          <div className="container-view-contex">
            <Header />
            <Switch>
              {
                nonAuthenticatedRoutes.map((prop, key) => {
                  if (prop.redirect) return (<Redirect from={prop.path} to={prop.to} key={key} />);
                  return (<Route path={prop.path} component={prop.component} key={key} />);
                })
              }
            </Switch>
          </div>
          <PhoneCallButton />
          <PlayStoreButton />
        </div>
      );
    } else {
      return <WelcomeLogo />
    }
  }
}

export default withRouter(LiteVersionContainer);