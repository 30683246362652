import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import ProtectedRoute from 'containers/App/ProtectedRoute.jsx';
import Cookies from 'universal-cookie';

import { authenticateDemoUser, getAppConfig, getClusterCookie } from '../../api/routes/api.route.js';

import 'assets/css/style.scss';
import Loader from '../../components/loader/loader';
import Modal, { Confirmation, Information, ModalIAgree } from '../../components/modal/modal.jsx';
import LocalData from '../../core/localData';
import Helper from '../../core/helper/helper';
import Emitter from '../../core/services';
import CallToAppSample from '../../components/modal/samples/CallToAppStoreSample';
import { initialPreSignup } from '../../api/routes/api.route';
import { HelperSchedule } from '../../views/schedule/core/helpers/helperSchedule';
import ScheduleService from '../../views/schedule/core/services/schedule.service.js';
import AndroidBack from '../../core/services/androidBack.service.js';
import 'react-toastify/dist/ReactToastify.css';

import { toast } from "react-toastify";
import LinkButton from '../../components/button/LinkButton/LinkButton.jsx';
import VideoTutorial from '../../components/modal/samples/videoTutorial.jsx';
import { SIDEBAR } from '../../assets/images/images.js';
import ModalCookieBanner from '../../components/modal/ModalCookieBanner.jsx';
import Analytics from '../../components/analytics/analytics.js';

class App extends Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refCallToAppModal = React.createRef();
    this.refModal = React.createRef();
    this.isLiteVersion = Helper.isLiteVersion();
    this.isHelpDeleteAccount = props.location.pathname.toString().endsWith('help-delete-account');
    this.state = {
      config: null,
      notFound: false,
      analyticCookies : this.cookies.get('acceptedAnalyticsCookies'),
    }
    this.analytics = new Analytics();
    this.prevCompanyDomain = this.cookies.get('prevCompanyDomain') ? this.cookies.get('prevCompanyDomain') : null;
    this.urlParams = new URLSearchParams(window.location.search);
    this.iagree = this.urlParams.get("iagree") === 'true';
    if (this.urlParams.get("schedule") === 'true') { LocalData.isSchedule = true; }
  }

  callToAppStoreTimer = null;

  componentDidMount() {

    if (this.state.analyticCookies) {
      setTimeout(() => {
        this.analytics.init();
      }, 2000);
    }

    if (!this.isLiteVersion && !this.cookies.get('sessionToken') && this.urlParams.get("openAppointment") === 'true'){
      this.props.history.push('/services-lite?openAppointment=true'); //push to openAppointment on lite version
    }
    this.cookies.remove('openAppointment', Helper.cookiesLiteConfig);
    this.cookies.remove('fromLite', Helper.cookiesLiteConfig);
       
    Emitter.on('REGISTER_STATE_CHANGED', (data) => {
      if (data.type === 'BasicClientDetails') {
        LocalData.userData = data;
        LocalData.userRegistered = false;
        this.props.history.push('/dados-cliente');
      } else {
        this.cookies.set('sessionToken', data.token, Helper.cookiesConfig);
        LocalData.userData = data;
        LocalData.userRegistered = true;
        window.location.href = `${process.env.PUBLIC_URL}/services`;
      }
    });
    if (!Helper.isLiteVersion() || Helper.checkLiteTokenChange()) LocalData.clearAllLiteData();
    if (!Helper.isLiteVersion() && !Helper.isHelpDeleteAccount()) {
      this.setPreSignup();
    } else {
      this.setClusterCookie();
    }
    this.cookies.set('prevCompanyDomain', Helper.companyDomain(), Helper.cookiesConfig);
    if (!Helper.companyDomain().includes("asuaempresa")) {
      this.cookies.set("kssreturncompany", Helper.companyDomain(), Helper.cookiesLiteConfig);
    }

    if (Helper.isAndroidWebview()) {
      AndroidBack.setHistory(this.props.history);
      window.AndroidBack = AndroidBack.handleAndroidBack;
    }

    // Fix to enable overscrolling on Android webview
    if (Helper.isAndroidWebview()) {
      const style = document.createElement('style');
      style.innerHTML = `
        @media (max-width: 768px) { body { min-height: calc(100vh + 1px); }} `;
      document.head.appendChild(style);
    }

    this.showVideoTutorial();

    Emitter.on('TOP_MODAL', (details) => {
      if (details.type === 'iagree') this.renderIAgreeModal(details);});

    Emitter.on('TOP_CONFIRMATION_MODAL', (details) => {
      this.renderConfirmationModal(details);});
  }

  componentWillUnmount() {
    Emitter.off('REGISTER_STATE_CHANGED');
    Emitter.off('TOP_MODAL');
    Emitter.off('TOP_CONFIRMATION_MODAL');
    if (Helper.isAndroidWebview()) {
      window.AndroidBack = null;
    }
    if (this.callToAppStoreTimer) {
      clearTimeout(this.callToAppStoreTimer);
    }
    this.analytics.unmount(); // Unmount UXCam
  }

  componentDidUpdate(prevProps, prevState) {
    const past = prevProps.location.pathname.toString();
    const current = this.props.location.pathname.toString();
    if (current !== past) {
      document.body.style.overflowY = 'auto';
      if (Helper.isAndroidWebview()) {
        try {
          window.Android.enablePullDownToRefresh();
        } catch (error) {
          console.error(error);
        }
      }
    }
    // If this.state.analyticCookies changes from false to true, initialize the analytics
    if (!prevState.analyticCookies && this.state.analyticCookies) {
      this.analytics.init();
    }
  }

  updateAnalyticCookies = (accepted) => {
    this.setState({ analyticCookies: accepted });
  };



  handleEventVideoTutorial = () => {
    this.renderModal(
      <VideoTutorial
        title={"Tutorial Cliente"}
        onClick={() => {
          this.refModal.current.closeModal();
        }}
        videoId="ZgPUE68tg-U" // Replace with your YouTube video ID
      />,
      false,
      true
    );
  };

  showVideoTutorial = () => {
    let tutorialTimer = null;
    const startTutorialTimer = () => {
      clearTimeout(tutorialTimer);
      tutorialTimer = setTimeout(() => {
        if (isAnyRelevantElementFocused()) {
          startTutorialTimer(); // Reset the timer if an element is focused
        } else {
          showToast();
        }
      }, 8000);
    };
    const isAnyRelevantElementFocused = () => {
      const activeElement = document.activeElement;
      return (
        activeElement &&
        (activeElement.tagName === 'INPUT' ||
          activeElement.tagName === 'TEXTAREA' ||
          activeElement.tagName === 'SELECT' ||
          activeElement.isContentEditable)
      );
    };
    const showToast = () => {
      this.cookies.set('videoTutorial', true, Helper.cookiesConfig);
      toast.info(
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <div style={{ height: '0.5em', width: '100%' }}></div>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <LinkButton
              onClick={() => this.handleEventVideoTutorial()}
              styleClass={`link-container center-text`}
              iconClass={`big-icon`}
              textClass={`big-text`}
              valueText={"Vídeo de Boas Vindas"}
              icon={SIDEBAR.videoDemoIcon}
            />
          </div>
          <div style={{ height: '0.5em', width: '100%' }}></div>
        </div>,
        Helper.toastThemeVideoOptions
      );
    };

    if (!this.cookies.get('videoTutorial') && !Helper.isLiteVersion()) {
      startTutorialTimer();
    }
    const cleanup = () => {
      clearTimeout(tutorialTimer);
    };
    window.addEventListener('beforeunload', cleanup);
  };  

  setPreSignup = async () => {
    const domain=Helper.companyDomain();
    const clientID=Helper.hasClientIDForLogin();
    let isDemoExpired = false;
    const handleRedirectToNewDemo = () => {
      const welcomeLink = Helper.welcomePageLink();
      const refLink = 'https://www.keymaster.pt/precos/?open=demo';
      const encodedRefLink = encodeURIComponent(refLink);
      return `${welcomeLink}?ref=${encodedRefLink}`;
    };

    if (Helper.isDemo() && Helper.hasClientIDForLogin()) {
      const demoAuth = {
        id: clientID,
        domain: domain,
      }
      const response = await authenticateDemoUser(demoAuth);
      if (response) {
        if (response.http_code || response.code) {
          if (response.http_code === 401) {
            this.setState({ notFound: true })
            this.renderModal(
              <Information
                title={"Empresa Demo"}
                text={"Ocorreu um erro a entrar nesta empresa Demo.\n Verifique o link utilizado.\n Obrigado."}
                onClick={() => {
                  this.refModal.current.closeModal();
                  window.location.href = handleRedirectToNewDemo();
                }} />
            );
          } else {
            this.setState({ notFound: true })
            this.renderModal(
              <Information
                title={"Empresa Demo Indisponível"}
                text={"A Empresa Demo indicada já não está disponível.\n Mas pode sempre criar outra demo ou trial!\n Carregue em OK para Continuar."}
                onClick={() => {
                  this.refModal.current.closeModal();
                  window.location.href = handleRedirectToNewDemo();
                }} />
            );
          }
          isDemoExpired = true;
        } else {
          this.cookies.set('sessionToken', response.data?.token, Helper.cookiesConfig);
          LocalData.userRegistered = true;
        }
      } else {
        this.setState({ notFound: true })
        this.renderModal(
          <Information
            title={"Empresa Demo Indisponível"}
            text={"A Empresa Demo indicada já não está disponível.\n Mas pode sempre criar outra demo ou trial!\n Carregue em OK para Continuar."}
            onClick={() => {
              this.refModal.current.closeModal();
              window.location.href = handleRedirectToNewDemo();
            }} />
        );
        isDemoExpired = true;
      }
    }
    
    if (!isDemoExpired) {
      const iagree = this.iagree;
      
      initialPreSignup(this.cookies.get('sessionToken'), this.prevCompanyDomain, iagree).then(res => {
        let error = false;
        if (!(res?.http_code === 409 && res?.code === 5 && res?.type === "Failure")) {
          LocalData.terms_accepted = true;
        }
        if (res?.type !== "Failure") error = true;
        if (this.setClusterCookie() === "ERROR") error = true;
        if (!error) this.getUserDetails();
      });
    }
  }
  
  getUserDetails = () => {
    ScheduleService.fetchUserDetails();
  }; 

  setClusterCookie = () => {
    if (process.env.NODE_ENV === 'development') {
      this.getInitialData();
    } else {
      getClusterCookie().then((data) => {
        if (data?.code === 404) {
          this.setState({ notFound: true })
          this.renderInfoModal('Prestador de serviços inexistente', 'O prestador de serviços introduzido não existe!')
          return "ERROR";
        } else if (data?.code === 401  || data?.code === 401) {
          return "ERROR";
        }
        else {
          this.getInitialData();
        }
      });
    }
  }

  getInitialData() {
    this.getInitialConfig();
  }

  getInitialConfig = () => {
    getAppConfig().then(data => {
      if (!data) {
        window.history.back();
        return;
      }
      Helper.saveConfig(data);
      this.setState({ config: data });
      
      this.renderCallToAppStore();
    }, () => window.location.back());
  }

  renderCallToAppStore = () => {
    if (Helper.hidePlayStore() || Helper.isLiteVersion()) {
      return;
    }
    const startTimer = () => {
      // Clear any existing timer
      if (this.callToAppStoreTimer) {
        clearTimeout(this.callToAppStoreTimer);
      }
      // Set a new timer
      this.callToAppStoreTimer = setTimeout(() => {
        // Check if there is already a modal or toast open
        if (!document.querySelector('.modal-container') && !document.querySelector('.Toastify__toast-container')) {
          this.refCallToAppModal.current.renderView(<CallToAppSample />);
          this.refCallToAppModal.current.openModal();
        } else {
          startTimer();
        }
      }, 15000);
    };
    // Start the initial timer
    startTimer();
  }

  closeCallToAppModal = () => this.refCallToAppModal.current.closeModal();

  renderConfirmationModal = (details) => {
    this.renderModal(
      <Confirmation
        title={details.title}
        text={details.message}
        confirmText={details.confirmText || "OK"}
        cancelText={details.cancelText}
        confirmationHandle={details.confirmationHandle} 
        declineHandle={() => this.refModal.current.closeModal()}
      />
    );
  }

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => {
          this.refModal.current.closeModal();
          Helper.logout(true);
        }
        }/>
    );
  }

  renderIAgreeModal(details) {
    this.renderModal(
      <ModalIAgree
        modalIsOpen={true}
        confirmText={details.confirmText}
        onClose={() => { this.refModal.current.closeModal();}}
        onConfirm={() => {this.refModal.current.closeModal();}}
        showClose={true}
        replaceCompanyName={details.replaceCompanyName}
        replaceVat={details.replaceVat}
        replaceClient={details.replaceClient}
        replaceItem={details.replaceItem}
        replaceWork={details.replaceWork}
        replaceCompanies={details.replaceCompanies}
        companyTerms={details.companyTerms}
        onClick={() => {this.refModal.current.closeModal();}}
      />
    ,false, true);
  }

  renderModal = (view, isMedia = false, occupyScreen=false) => {
    this.refModal.current.renderView(view, isMedia, occupyScreen);
    this.refModal.current.openModal();
  }

  render() {
    const activateLoggin = (!HelperSchedule.isSchedulePage() && !this.isLiteVersion && !this.isHelpDeleteAccount  && this.cookies.get('sessionToken')) || !LocalData.userRegistered;
    if (this.state.config) {
      return (
        <React.Fragment>
          <Switch>
            <ProtectedRoute {...this.props} path="/" loggedIn={activateLoggin} />
          </Switch>
          <div className="d-flex justify-content-center text-center" lang={"pt"}>
            <Modal ref={this.refCallToAppModal} />
          </div>
          <Modal ref={this.refModal}/>
          <ModalCookieBanner updateAnalyticCookies={this.updateAnalyticCookies}/>
        </React.Fragment>
      );
    } else if (this.state.notFound) {
      return(
        <React.Fragment>
          <div className="d-flex justify-content-center text-center" lang={"pt"}>
            <Modal ref={this.refModal}/>
          </div>
        </React.Fragment>
      )
    } else {
      return(
        <Loader></Loader>
      )
    }
  }
}

export default withRouter(App);
