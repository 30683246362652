// React Imports
import React from 'react';
// Assets
import { SIDEBAR } from 'assets/images/images.js';
// Styles
import 'components/card/equipment/equipmentCard.style.scss';
import Helper from '../../../core/helper/helper';
import RightArrowButton from '../../button/rightArrowButton';

class CardEquipment extends React.Component {

  constructor(props){
    super(props);
    this.isInternalLiteEquip = typeof this.props.data?.id === 'string' && this.props.data.id.includes("int");
    this.state = {
      photoURL: Helper.isLiteVersion()? (props.data.image?.includes('base64') ? props.data.image : '' ) : Helper.urlItemImage(props.data.image),
      service: props.service,
      darkText: props.darkText
    }
  }

  handlePressEventArea1 = () => { 
    this.props.onClickArea1();
  }

  /**
   *  É activo caso a equipamento tenha algum serviço pendente
   */
  renderSerciveButton = (value) => {
    if(value != null){
      return(
        <div className='card-equipment-button-container' onClick={() => this.handlePressEventArea1()}>
          <img src={SIDEBAR.serviceDetails} alt=""/>
        </div>
      );
    }
  }

  renderItemOverlay = (value) => {
    if(value != null && this.props.darkText){
      return(
        <div className='card-equipment-not-available'></div>
      );
    }
  }

  renderValue = (value, random) => {
    return (!this.isInternalLiteEquip && Helper.isLiteVersion() && (!window.location.pathname.toString().includes("schedule-lite"))) ? (
      <>
        <span>
          {(() => {
            const processedValue = Helper.printValue(value);
            if (processedValue.includes(" ") || processedValue.includes("-")) {
              return processedValue.split(" ")[0].split("-")[0];
            } else {
              return processedValue.slice(0, 2);
            }
          })()}
        </span>
        <span className='pixelized'>{random}</span>
      </>
    ) : (
      Helper.printValue(value)
    );
  };

  render() {
    const { data: { title, subtitle, workId }} = this.props;
    const randomTitle = Helper.newRandomString(
      Helper.printValue(title)?.length - Helper.printValue(title)?.slice(" ")[1]?.length
    );
    const randomSubtitle =
      Helper.newRandomString(
        Helper.printValue(subtitle)?.length - Helper.printValue(subtitle)?.slice(" ")[1]?.length
      );
    return (
      <div className='card-equipment-main-container' role={'button'} onClick={() => this.handlePressEventArea1()}>
        {this.renderItemOverlay(workId)}
        <div className='card-equipment-info-container' >
          <div className='card-equipment-image-container'>
            <img
              src={this.state.photoURL}
              alt="serviceImage"
              className={!this.isInternalLiteEquip && Helper.isLiteVersion() ? "pixelized" : ""}
              onError={(e) => { e.target.src = Helper.defaultImage(); e.target.onerror = null; }}
            /> 
          </div>
          <div className='card-equipment-text-container'>
            <div className={`card-text-title ${this.props.darkText ? 'card-text-dark' : ''}`}>
              {this.renderValue(Helper.formatTitleIfBirthday(title,true), randomTitle)}
            </div>
            <div className={`pt-1 card-text-subtitle ${this.props.darkText ? 'card-text-dark' : ''}`}>
              {this.renderValue(Helper.formatTitleIfBirthday(subtitle,false), randomSubtitle)}
            </div>
          </div>
        </div>
        <RightArrowButton class={'w-100'}/>
      </div>
    );
  }
}

export default CardEquipment;