import React, { Fragment, useEffect, useState } from "react";
import './CompanyCard.scss';
import { ASSETS, GENERAL } from "../../../assets/images/images";
import { CompanyCardPropTypes } from "../../../core/models/props-interface.d";
import Helper from "../../../core/helper/helper";
import LinkButton from "../../button/LinkButton/LinkButton";

/**
 * @param {Object} props
 * @param {Company} props.company - The company object.
 * @param {boolean=} props.isDetailsCard - (Optional) Whether it is a details card.
 * @param {(c: Company) => void} props.onSelect - A function to handle the click event.
 */
const CompanyCard = (props) => {
  const { isDetailsCard } = props;
  const cardStyle = isDetailsCard ? 'w-100 h-auto d-inline-flex' : '';
  const [company, setPropValue] = useState(props.company);

  useEffect(() => {
    setPropValue(props.company);
  }, [props.company]);

  const handleOnSelect = () => {
    props.onSelect(company);
  }
  const renderLogoContainer = () => {
    const renderAddButton = () => {
      if (isDetailsCard || company?.registered) {
        return (<Fragment />);
      }
      return (
        <div className="add-button-container">
          <img src={GENERAL.iconPlus} alt="add-company" />
        </div>
      );
    }

    return (
      <div className="company-logo flex-center">
        {!Helper.isDemo() && !company?.logoUrl ? (
          <div className="company-name-logo">{company?.name}</div>
        ) : (
          <img
            src={company?.logoUrl || ASSETS.clientLogo}
            alt="company-logo"
            onError={(e) =>
              (e.target.src = Helper.isDemo() ? GENERAL.defaultCompanyLogo : ASSETS.clientLogo)
            }
          />
        )}
        {renderAddButton()}
      </div>
    );
  }

  const renderLocalization = () => {
    const address = company?.address ? company?.address : '';
    return (
      <div
        className={`company-localization ${isDetailsCard ? "details" : ""
          } mt-1 d-inline-flex justify-content-between align-items-center`}
      >
        <LinkButton
          onClick={() => { }}
          styleClass={`link-container ${isDetailsCard ? "details" : ""}`}
          textClass="small"
          isMaps={isDetailsCard}
          valueLink={isDetailsCard ? (company?.name + ', ' + address) : ''}
          valueText={company?.town}
          isDetailsCard={isDetailsCard}
        />
        {renderDistance()}
      </div>
    );
  }

  const renderDistance = () => {
    if (!company?.distance) {
      return <Fragment></Fragment>;
    }

    return (
      <div className="distance ms-1 text-color-grey-blue">
        {company?.distance ? Helper.formatDistance(company?.distance) : ""}
      </div>
    );
  };

  return (
    <div className={`company-card ${cardStyle}`} role="button" id="company-card" onClick={() => handleOnSelect()}>
      {renderLogoContainer()}
      <div className={`company-info ${isDetailsCard ? 'ms-3' : ''}`}>
        <div className={`mt-1 company-name${isDetailsCard ? ' details' : ''}`}>{Helper.isDemo(company?.name)? "Empresa Demo" : company?.name}</div>
        {renderLocalization()}
      </div>
    </div>
  );
}
CompanyCard.propTypes = CompanyCardPropTypes;
export default CompanyCard;
