// React Imports
import React from "react";
import { withRouter } from "react-router";
import Cookies from "universal-cookie";
// Components
import CardEquipment from "../../components/card/equipment/equipmentCard.jsx";
import Toast from "components/sidebar/toast/toast.js";
import Loader from "../../components/loader/loader";
// API Imports
import { getItemList, addItemToUser } from "api/routes/api.route.js";
import Labels from "../../variables/labels";
import Modal, { Information } from "../../components/modal/modal";
import Helper from "../../core/helper/helper";
import AddEquipment from "../../components/addEquipment/addEquipment";
import LocalData from "../../core/localData";
import IAgreeBox from "../../components/iagree/iagreeBox.jsx";
import Button from "../../components/button/button.jsx";

class MyEquipments extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    this.refToast = React.createRef();
    this.refPlateList = React.createRef();
    this.isLiteVersion = Helper.isLiteVersion();
    this.state = {
      data: null,
      error: "",
      inputStatus: false,
      isLoading: LocalData.terms_accepted,
      bottomBarTop: 54,
      itemListHeight: "auto",
      hideBottomShadow: true,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  componentDidMount() {
    this.getItemListData();
    // this.setItemListHeight();
    // this.setBottomBarTop();
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentDidUpdate(_, prevState) {
    const { inputStatus } = this.state;
    if (inputStatus && !prevState.inputStatus) {
      setTimeout(() => {
        this.setItemListHeight();
      }, 200);
    }
  
  }

  handleWindowResize = () => {
    // this.setBottomBarTop();
    this.setItemListHeight();
  };

  scrollListener = () => {
    const scrollContainer = document.getElementById("itemListContainer");
    // Adiciona listener apenas se o container estiver visível
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", this.handleScrollEvent);
      this.scrollContainer = scrollContainer;
    }
  }


  // Handler do evento de scroll
  handleScrollEvent = (event) => {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight; // Altura total do conteúdo
    const clientHeight = event.target.clientHeight;
    if (scrollHeight - scrollTop - clientHeight < 20) {
      this.setState({ hideBottomShadow: true });
    } else {
      this.setState({ hideBottomShadow: false });
    }
    // Lógica adicional aqui
  };

  // Method to calculate the height of elements dynamically
  setItemListHeight = () => {
    const scrollable = document.getElementById("itemListContainer");
    if (scrollable) {
      const scrollTop = scrollable.getBoundingClientRect().top;
      const element = document.getElementById("addItemButton");

      if (element) {
        const elementHeight = element.getBoundingClientRect().height;
        const smallContainer = scrollable.scrollHeight <= scrollable.clientHeight;
        
        if (window.innerWidth > 768) {
          const listHeight = window.innerHeight - scrollTop - elementHeight;
          const hideBottomShadow =
            listHeight - elementHeight < 50 || smallContainer;

          this.setState({
            itemListHeight: listHeight,
            bottomBarTop: 0,
            hideBottomShadow,
            smallContainer
          });

        } else {
          const bar = document.getElementById("bar-container");
          if (bar) {
            const barHeight = bar.getBoundingClientRect().height;
            const listHeight = window.innerHeight - scrollTop - barHeight - elementHeight;

            const hideBottomShadow = listHeight - elementHeight < 50 || smallContainer;

            this.setState({
              itemListHeight: listHeight,
              bottomBarTop: barHeight,
              hideBottomShadow,
              smallContainer
            });
          }
        }
      }
    }
  };

  /**
   * Pedido para retornar a lista de equipamentos do utilizador
   */
  getItemListData = () => {
    const isValidItem = (item) => {
      if (!item) return false;
      const keys = Object.keys(item);
      // Exclude `image` and check if there are any other properties
      const validKeys = keys.filter((key) => key !== "image");
      return validKeys.length > 0;
    };

    if (!this.isLiteVersion) this.callGetItemsApi();

    if (!LocalData.equipmentsData?.length && !LocalData.liteData) {
      return;
    }

    const liteDataItem = isValidItem(LocalData.liteData?.item) ? LocalData.liteData?.item : null;
    if (liteDataItem) {
      let doNothingFlag = false;
      let id;
      let existingItem;
      if (liteDataItem.fo_id) {
        existingItem = LocalData.equipmentsData?.find((item) => item.id === liteDataItem.fo_id);
        if (existingItem) {
          doNothingFlag = true;
        } else {
          id = liteDataItem.fo_id;
        }
      } else {
        if (liteDataItem.title && liteDataItem.subtitle) {
          existingItem = LocalData.equipmentsData.find(
            (item) => item.title === liteDataItem.title && item.subtitle === liteDataItem.subtitle
          );
          if (existingItem) {
            doNothingFlag = true;
          } else {
            id = Helper.getNextInternalEquipID();
          }
        }
      }
      if (!doNothingFlag) {
        const transformedItem = {
          id: id,
          title: liteDataItem.title,
          subtitle: liteDataItem.subtitle,
          photo: liteDataItem.photo,
          image: liteDataItem.image,
        };
        const updatedEquipmentsData = [...LocalData.equipmentsData, transformedItem];
        LocalData.equipmentsData = updatedEquipmentsData;
        const query = this.props.location.search;
        const token = new URLSearchParams(query).get("token");
        const tokenCal = new URLSearchParams(query).get("token_cal");
        if (token) {
          LocalData.equipmentsToken = token;
        }
        if (tokenCal) {
          LocalData.equipmentsTokenCal = tokenCal;
        }
        if (!token && !tokenCal) LocalData.equipmentsToken = 0;
      }
    } else {
      //TODO: Check if there is any item in showcase API
    }

    this.setState({
      data: LocalData.equipmentsData,
      isLoading: false,
    });
  };

  callGetItemsApi() {
    if (LocalData.terms_accepted) {
      getItemList(this.cookies.get("sessionToken")).then((value) => {
        if (value) {
          if (value.code) {
            this.setState({
              data: [],
              error: true,
            });
          } else {
            this.setState({
              data: [],
              isLoading: false,
            });
            const items = value.items;
            // const items = value.items.slice(0, 3);
            LocalData.equipmentsData = items;
            this.setState({
              data: items,
              error: false,
            });
          }
          setTimeout(() => {
            this.setState(
              {
                isLoading: false,
              },
              () => {
                this.setItemListHeight();
                this.scrollListener();
              }
            );
          }, 100);
        } else {
          this.setState({
            data: [],
            error: true,
          });
          setTimeout(() => {
            this.setState(
              {
                isLoading: false,
              },
              () => {
                this.setItemListHeight();
                this.scrollListener();
              }
            );
          }, 100);
        }
      });
    }
  }

  /**
   * Pedido para adicionar uma nova equipment
   *
   * @param {any} newItem - objecto com o valor da equipamento a inserir
   */
  callAddItem = (newItem) => {
    this.setState({ isLoading: true });
    addItemToUser(this.cookies.get("sessionToken"), newItem).then((value) => {
      this.setState({ isLoading: false });
      if (value) {
        if (value.code) {
          if (value.code === 401) {
            return;
          }
          if (value.code === 409) {
            Modal.create(
              <Information
                title={Labels.registedErrorTitle}
                text={value.message}
                onClick={() => Modal.close()}
              />
            );
          } else if (value.code === 429) {
            Modal.create(
              <Information
                title={Labels.registedErrorTitle}
                text={value.message}
                onClick={() => Modal.close()}
              />
            );
          } else {
            Modal.create(
              <Information
                title={Labels.registedErrorTitle}
                text={Labels.genericErrorMessage}
                onClick={() => Modal.close()}
              />
            );
          }
        } else {
          Modal.create(
            <Information
              title={Labels.registedSuccessTitle}
              text={value.message}
              onClick={() => Modal.close()}
            />
          );
          this.getItemListData();
        }
      } else {
        Modal.create(
          <Information
            title={Labels.registedErrorTitle}
            text={Labels.genericErrorMessage}
            onClick={() => Modal.close()}
          />
        );
      }
      this.handleChangeInputEvent();
    });
  };

  /**
   * Visualizar details sobre a equipamento selecionada
   *
   * @param {string} id - id da equipment
   */
  handlePressEventDetails = (id) => {
    this.props.handleBackButton();
    if (this.props.embeddedSchedule) {
      this.props.handleClickNewSchedule(id);
    } else {
      this.props.history.push(`/equipment-details/${id}`);
    }
  };

  /**
   * Evento para activar o modo de adiçao de equipment
   */
  handleChangeInputEvent = () => {
    this.setState((prevState) => ({
      inputStatus: !prevState.inputStatus,
    }));
  };

  /**
   * Evento para renderizar o campo para adicionar items
   */
  changeInputRender = () => {
    const { inputStatus } = this.state;
    if (inputStatus) {
      return (
        <div className="mt-2 mb-3 mb-md-0" style={{ width: "90%" }}>
          <AddEquipment
            onClick={(data) => this.handleEventAddItem(data)}
            isForm={true}
          ></AddEquipment>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div className="cta-bottom-container mt-3 w-100 px-1 flex-center">
            <div
              className="cta-button-secondary mb-4 w-100 d-flex justify-content-center align-items-center"
              role="button"
              onClick={() => this.handleChangeInputEvent()}
            >
              {`+ Adicionar ${Helper.itemLabel()}`}
            </div>
          </div>
          {this.isLiteVersion && LocalData.liteData?.is_client && (
            <Button
              type="link"
              onClick={Helper.logoutFromLite}
              className="link-container mb-4"
              //textClass="small light"
              valueText={"Iniciar Sessão"}
            />
          )}
        </React.Fragment>
      );
    }
  };

  /**
   * Validação do valor da matricula inserida
   *
   * @param {string} value - valor da matricula
   */
  plateValidater = (value) => {
    if (value.length < 8) {
      this.refToast.current.triggerToast("Formato Inválido", "alert");
      return false;
    }
    return true;
  };

  /**
   * Evento para validação da Equipamento a ser inserido
   */
  handleEventAddItem = (event) => {
    const fields = event;
    const newItem = Helper.buildEquipmentSpecsBody(fields);
    if (!this.isLiteVersion) {
      this.callAddItem(newItem);
    } else {
      const query = this.props.location.search;
      const token = new URLSearchParams(query).get("token");
      const tokenCal = new URLSearchParams(query).get("token_cal");
      Helper.buildLiteEquipmentData(fields, token, tokenCal);
      LocalData.liteItem = true;
      this.getItemListData();
      this.handleChangeInputEvent();
    }
  };

  /**
   * Apresentar lista de equipments
   */
  renderEquipmentMessage = () => {
    const { data, error, isLoading } = this.state;
    if (isLoading) {
      return null;
    }
    if (error) {
      return <p className="menu-state-message"> {Labels.errorEmptyEquipments} </p>;
    } else if (!data?.length && this.props.embeddedSchedule && !isLoading) {
      return (
        <p className="menu-state-message px-3">
          {Labels.emptyEquipments1(Helper.itemLabel(), Helper.itemPronoun())}
          <br />
          {Labels.emptyEquipments2(Helper.itemLabel(), Helper.itemPronoun())}
        </p>
      );
    } else if (this.props.embeddedSchedule && !isLoading) {
      return (
        <p className="menu-state-message pb-2" style={{ width: "90%" }}>
          {Labels.chooseEquipment(Helper.itemLabel(), Helper.itemPronoun())}
        </p>
      );
    }

    return <p className="menu-state-message pb-2" style={{ width: "90%", minHeight: "1px" }}>
      {Labels.equipmentTitle(Helper.itemLabel(), Helper.itemPronoun1(), Helper.itemPronoun2())}
    </p>;
  };

  /**
   * Representa a lista de equipamentos do Utilizador
   */
  renderListOfEquiments = () => {
    const { data } = this.state;
    if (!data?.length) {
      return;
    }
    return (
      <>
        {data.map((prop, key) => {
          return (
            <CardEquipment
              key={key}
              data={prop}
              onClickArea1={() => this.handlePressEventDetails(prop.id)}
            />
          );
        })}
        <div style={{ height: "30px" }}></div>
      </>
    );
  };

  renderLoader = () => {
    const { isLoading } = this.state;
    if (isLoading) {
      return <Loader message="" inverted={false} local={true} big={false}></Loader>;
    }
    return;
  };

  render() {
    const { itemListHeight, smallContainer} = this.state;
    // const inputFieldsCount = Helper.itemFieldsToAdd().length;
    return (
      <div className={this.props.embeddedSchedule ? "" : "side-bar-menu-container-block text-white mt-4 mt-md-5 pt-1"}>
        {(LocalData.terms_accepted || this.isLiteVersion) && (
          <React.Fragment>{this.renderEquipmentMessage()}</React.Fragment>
        )}
        {/* <div className="top-shadow-container dark"></div> */}
        <div
          id="itemListContainer"
          style={{ height: smallContainer ? "auto" : itemListHeight, maxHeight: itemListHeight }}
          className={`menu-info scrollable-container pt-2`}
        >
          {!LocalData.terms_accepted && (
            <IAgreeBox
              url={Helper.addOrUpdateSearchParam(window.location, "openEquipments", true)}
            />
          )}
          {(LocalData.terms_accepted || this.isLiteVersion) && (
            <React.Fragment>
              {this.renderListOfEquiments()}
              {/* {this.renderAddItemButton('')} */}
            </React.Fragment>
          )}
        </div>
        {this.renderAddItemButton()}
        {this.renderLoader()}
        <Toast ref={this.refToast} />
      </div>
    );
  }

  renderAddItemButton() {
    const { bottomBarTop, hideBottomShadow} = this.state;
    return (
      <div
        id="addItemButton"
        style={{ bottom: bottomBarTop }}
        className={`fixed-buttons-wrapper ${!hideBottomShadow ? "box-shadow" : ""}`}
        >
        {/* <div className="w-100 h-100 position-relative flex-center"> 
          </div> */}
          {this.changeInputRender()}
      </div>
    );
  }
}

export default withRouter(MyEquipments);
