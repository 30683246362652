// 1. Third-party libraries
import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";

// 2. Styles
import "./Payment.scss";

// 3. Services and helpers
import Helper from "../../core/helper/helper";
import { analyseUrlWorkAnalytics } from "../../core/services/analytics.service";
import { getFoDetails, getScheduleDetails } from "../../api/routes/api.route";
import PaymentService from "../../core/services/payment.service";
import { PaymentActive, PaymentMethod, PaymentState } from "../../core/types";
import ScheduleService from "../schedule/core/services/schedule.service";

// 4. Application components
import Modal, { Information } from "../../components/modal/modal";
import ScheduleServiceDetailsCard from "../schedule/components/scheduleServiceDetailsCard/ScheduleServiceDetailsCard";
import TasksList from "../../components/list/tasks/tasks";
import PaymentMethods from "./PaymentMethods";
import PaymentStateDetails from "./PaymentStateDetails";
import LocalData from "../../core/localData";


class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = createRef();
    this.isLiteVersion = Helper.isLiteVersion();
    const params = new URLSearchParams(this.props.location.search);
    this.state = {
      id: props.match.params.id,
      isAppointment: props.match.params.id?.substring(0, 2) === "S-",
      analyticsCalled: false,
      location: undefined,
      isFetching: false,
      /** @type {ScheduleData}*/
      serviceData: {},
      tasksData: {},
      payment: {},
      paymentMethods: LocalData.configData?.payment?.methods,
      urlParams: {
        status: params?.get("status"),
        id: params?.get("id"),
        requestId: params?.get("requestId"),
        sk: params?.get("sk"),
        brand: params?.get("brand"),
        pan: params?.get("pan")
      }
    };
    this.timeoutId = null;
  }

  componentDidMount() {
    this.callAnalytics();
    window.scrollTo(0, 0);
    if (!this.isLiteVersion) {
      this.getServiceDetails();
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId); // Clear the timeout to stop polling
      //console.log("Polling stopped due to component unmount.");
    }
  }

  /* ###################
  /*  API CALLS *
  /* ################## */

  getServiceDetails = async () => {
    const { match } = this.props;
    if (match.params.id && match.params.id.substring(0, 2) === "S-") {
      const appointmentId = match.params.id;
      getScheduleDetails(this.cookies.get("sessionToken"), appointmentId).then((value) => {
        if (value) {
          if (value.code) {
            this.handleValueCodeResponse(value);
          } else {
            const { token, ...valueWithoutToken } = value;
            const updatedValue = {
              ...valueWithoutToken,
              token_cal: token,
            };
            this.buildServiceDetails(updatedValue);
          }
        }
      });
    } else {
      getFoDetails(match.params.id, this.cookies.get("sessionToken")).then((value) => {
        if (value) {
          if (value.code) {
            this.handleValueCodeResponse(value);
          } else {
            this.buildServiceDetails(value);
          }
        }
      });
    }
  }

  handleSubmitPayment = (method, data) => {
    // const { match } = this.props;
    // const id = match.params.id?.split("-")[1];
    const { id, payment: { total } } = this.state;
    try {
      if (!id) {
        throw new Error("Error handleSubmitPayment: No Service ID provided", id);
      }
      this.handlePaymentResponse({ id, method, phone: data?.phone, total });

    } catch (error) {
      console.error("Error handleSubmitPayment", error);
    }
  };

  getPaymentState = async () => {
    const { payment } = this.state;
    const intervalDuration = payment?.details?.type === PaymentActive.MBWAY ? 5000 : 10000;
    try {
      const response = await PaymentService.getPaymentStatus(this.state.id);
      
      if (response?.success) {
        //console.log('getPaymentState SUCCESSFULLY', response);
  
        this.setState(prevState => ({ payment: { ...prevState?.payment, state: response?.data } }));
  
        // Check if the payment state is still PENDING and type is set
        if (response?.data === PaymentState.PENDING && payment?.details?.type) {
          //console.log(`Payment state is PENDING, continuing polling in ${intervalDuration / 1000}s...`);
          this.timeoutId = setTimeout(this.getPaymentState, intervalDuration); 
        } else {
          //console.log("Payment state is no longer PENDING, stopping polling.");
          if (response?.data === PaymentState.PAYED) {
            //console.log("Payment state is PAYED.");
            this.props.history.push(`/service-details/${this.props.match.params.id}?s=I&t=0`);
          }
        }
      } else {
        //console.error("Error fetching Payment State");
        this.timeoutId = setTimeout(this.getPaymentState, intervalDuration);
      }
    } catch (error) {
      //console.error("Error fetching Payment State:", error);
    }
  };
  

  updateCCState = async () => {
    const { urlParams, payment } = this.state;
    try {
      this.setState({isFetching: true});
      let response = await PaymentService.updateCCState(
        { id: payment?.details?.id, status: urlParams?.status, sk: urlParams?.sk }
      );
      //console.log('updateCCState', response);
      if (response) {
        this.setState({isFetching: false});
      }
      if (!response.success) {
        // This API is Fire and Forget, so we don't need to handle the response
        //this.renderError();
        this.setState({isFetching: false});
      }
    } catch (error) {
      console.error("Error updateCCState", error);
    }
  };

  /* ###################
  ** HELP METHODS *
  /* ################## */
  callAnalytics() {
    if (this.state.analyticsCalled) {
      return null;
    }
    analyseUrlWorkAnalytics(window.location.href);
    this.setState({ analyticsCalled: true });
  }

  handleValueCodeResponse = (value) => {
    if (value.code === 401) {
      return;
    } else if (value.code === 404) {
      this.props.history.push(`/services`);
    } else if (value.code === 409) {
      this.renderModal(
        <Information
          title="Acesso Não Autorizado"
          text={value.message}
          onClick={() => {
            this.refModal.current.closeModal();
            this.props.history.push(`/services`);
          }}
        />
      );
    } else if (value.code === 406) {
      this.renderInfoModal("Formato Inválido", value.message);
    } else if (value.code === 301) {
      if (this.goToFeedback) {
        this.props.history.replace(`/service-details/${value.id}?s=I&t=0&feedback=t`);
      } else {
        this.props.history.replace(`/service-details/${value.id}?s=I&t=0`);
      }
    } else {
      this.renderModal(
        <Information
          title="Pedido Sem Sucesso"
          text="Foi encontrado um problema durante o pedido, por favor tente mais tarde"
          onClick={() => {
            this.refModal.current.closeModal();
            this.props.history.push(`/services`);
          }}
        />
      );
    }
  };

  buildServiceDetails = async (data) => {
    const { urlParams, isAppointment } = this.state;
    //console.log('urlParams', urlParams);
    
    if (data?.payment?.state === PaymentState.PAYED) {
      //console.log("Payment state is PAYED.", this.props.match.params.id);
      this.props.history.push(`/service-details/${this.props.match.params.id}?s=I&t=0`);
      return;
    }
    
    const itemDetails = await ScheduleService.fetchItemDetails(data?.item?.id);
    this.setState((prevState) => ({
      location: data?.establishment?.name,
      hasServices: !!data.services?.length,
      /** @type {ScheduleData} */
      serviceData: {
        itemDetails,
        selectedDate: isAppointment && data?.date_end ? `${data?.date_start} ${data?.time}` : data?.date_start,
        selectedTime: { value: data?.time },
        dateEnd: isAppointment && data?.date_end ? `${data?.date_end} ${data?.time_end}` : data?.date_end,
        state: data?.state
      },
      tasksData: {
        state: data?.state?.bars,
        serviceDetail: {
          total: data?.payment?.total ? data?.payment?.total?.toFixed(2).replace('.', ',') : data?.total || 0,
          totalDiscount: data?.total_discount || 0,
          items: data.services?.length ? data.services : data?.symptoms?.map(s => ({
            completed: s.completed,
            designation: s.name,
            discount: s.discount,
            price: s.schedule_payment_value
          })),
        }
      },
      /** @type {Payment} */
      payment: {
        ...prevState?.payment,
        expirationDate: data?.payment?.valid_until,
        total: data?.payment?.total,
        description: data?.payment?.description,
        /** @type {PaymentDetails} */
        details: PaymentService.detailsMapper(data?.payment?.details),
        state: data?.payment?.state,
        method: data?.payment?.method,
        urlParams: this.state.urlParams
      }
    }));

    if (urlParams?.status) {
      this.updateCCState();
    }

    if (data?.payment?.state === PaymentState.PENDING && data?.payment?.details?.type) {
      setTimeout(() => {
        this.getPaymentState();
      }, 1000);
    }
  };

  handlePaymentResponse = async (data) => {
    try {
      this.setState({isFetching: true});
      let response = await PaymentService.callPaymentApi(data);
      //console.log('handlePaymentResponse', response);
      if (response?.success) {
        this.setState(prevState => ({
          /** @type {Payment} */
          payment: {
            ...prevState.payment,
            details: {...prevState.payment?.details, ...response?.details},
          }
        }), () => {
          if (data.method === PaymentMethod.CC) {
            this.openCCUrl(response?.details);
          } else {
            this.getPaymentState();
          }
        });
      } else {
        this.renderError();
      }
      if (response) {
        this.setState({isFetching: false});
      }
    } catch (error) {
      this.renderError();
      console.error("Error handlePaymentResponse", error);
    }
  }

  handleScrollDown = () => {
    setTimeout(() => {      
      // Scroll the window to the bottom
      window.scrollTo({
        top: document.documentElement.scrollHeight, // Scroll to the bottom of the document
      });
    
      // Scroll a specific element with the id 'paymentPageContainer' to the bottom
      const el = document.getElementById("scrollContainer");
      if (el) {
        el.scrollTo({ top: el.clientHeight, behavior: "smooth" });
      }
    }, 100);
  };

  /* ###################
  ** RENDER *
  /* ################## */

  renderTasksList = () => {
    const { tasksData: { state, serviceDetail }, hasServices } = this.state;
    return !serviceDetail ? null : (
      <TasksList
        title={hasServices ? "Serviços Realizados" : "Serviços Solicitados"}
        data={serviceDetail}
        state={state}
        isAppointment={false}
      />
    );
  };

  renderPaymentMethods = () => {
    const { payment, paymentMethods, isFetching } = this.state;
    return !paymentMethods?.length ? null : (
      <PaymentMethods
        methods={paymentMethods}
        payment={payment}
        method={payment.method}
        onMethod={this.handleScrollDown}
        onPayment={this.handleSubmitPayment}
        isLoading={isFetching}
        />
    );
  };

  renderPaymentState = () => {
    const { /** @type {Payment} */ payment } = this.state;
    return <PaymentStateDetails payment={payment}/>
  };

  openCCUrl = (details) => {
    if (details.url) {
      const target = "_self";
      window.open(details.url, target);
    }
  };

  render() {
    const { serviceData, location, isAppointment } = this.state;
    return (
      <div className="main-details-container px-0 px-md-4 pt-3" id="paymentPageContainer">
         <div className="h-100 schedule-page-container container px-3 pb-5 pt-3 w-100 d-flex align-items-start justify-content-center" id="scrollContainer">
          <div className="w-lg-75 w-xl-50">
            <div className="w-100">
              <ScheduleServiceDetailsCard
                scheduleData={serviceData}
                isPayment={true}
                location={location}
                isAppointment={isAppointment}
                hasServiceFixedDate={isAppointment && serviceData?.dateEnd} />
            </div>
            <div className="w-100 mt-3">
              {this.renderTasksList()}
            </div>
            <div className="w-100 mt-3">
              {this.renderPaymentMethods()}
              {/* {this.renderPaymentState()} */}
            </div>
          </div>
          </div> 

        <Modal ref={this.refModal} />
        <Helmet>
          <title> {Helper.titlePaymentPage()} </title>
        </Helmet>
      </div>
    );
  }

  renderModal = (view, isMedia = false, occupyScreen = false) => {
    this.refModal.current.renderView(view, isMedia, occupyScreen);
    this.refModal.current.openModal();
  }

  renderError = () => {
    this.renderModal(
      <Information
        title="Pedido Sem Sucesso"
        text="Foi encontrado um problema durante o pedido, por favor tente mais tarde, obrigado"
        onClick={() => {
          this.setState({isFetching: false});
          this.refModal.current.closeModal();
        }}
      />
    );
  };
}

export default withRouter(PaymentPage);
