import React from 'react';
import {
  Route, Switch, Redirect, withRouter,
} from 'react-router-dom';

import Header from 'components/header/header.jsx';
import Sidebar from 'components/sidebar/sidebar.jsx';

import { nonAuthenticatedRoutes } from 'routes/app.routes.js';
import PlayStoreButton from '../components/button/playstoreButton';
import Helper from '../core/helper/helper';


class SimpleNonAuthContainer extends React.Component {
  constructor() {
    super();
    this.isLiteVersion = Helper.isLiteVersion()
    this.state = {
      showWelcome: !Helper.isFO()
    }
  }
  render() {
    return (
      <div className="container-body" lang={"pt"}>
        <Sidebar />
        <div className="container-view-contex">
          <Header />
          <Switch>
            {
              nonAuthenticatedRoutes.map((prop, key) => {
                if (prop.redirect) return (<Redirect from={prop.path} to={prop.to} key={key} />);
                return (
                  <Route
                    path={prop.path}
                    exact={prop.exact} // Assuming you might have exact prop
                    key={key}
                    render={(props) => {
                      // Use a timestamp to force re-render
                      const uniqueKey = Date.now();
                      // Render the component with a unique key
                      return React.createElement(prop.component, {
                        ...props,
                        key: uniqueKey,
                      });
                    }}
                  />
                );
              })
            }
          </Switch>
        </div>
        {/* <PhoneCallButton /> */}
        <PlayStoreButton />
      </div>
    );
  }
  // else {
  //   return <WelcomeLogo />
  // }
  // }
}

export default withRouter(SimpleNonAuthContainer);