import React, { Component } from "react";
import {
	List,
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache,
} from "react-virtualized";
import CalendarHelper from "../../core/helper/calendarHelper";
import moment from "moment";

class NotificationsListVirtualized extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notificationIDSpinnerDelete: [],
			notificationIDSpinnerRead: [],
		};

		this.cache = new CellMeasurerCache({
			fixedWidth: true,
			defaultHeight: 56,
		});

		this.handleResize = this.handleResize.bind(this);
		this.listRef = React.createRef();
	}

	clearCache = () => {
		this.cache.clearAll();
	};

	forceUpdateList = () => {
		if (this.listRef.current) {
			this.listRef.current.forceUpdateGrid();
		}
	};

	handleResize() {
		this.clearCache();
		this.forceUpdateList();
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.notifications !== this.props.notifications) {
			this.clearCache();
			this.forceUpdateList();
		}
	}

	handleRead = (notificationId, url) => {
		this.setState(
			(prevState) => ({
				notificationIDSpinnerRead: [
					...prevState.notificationIDSpinnerRead,
					notificationId,
				],
			}),
			() => {
				this.cache.clearAll();
				if (this.listRef.current) {
					this.listRef.current.forceUpdateGrid();
				}
			}
		);
		setTimeout(() => {
			this.setState(
				(prevState) => ({
					notificationIDSpinnerRead: prevState.notificationIDSpinnerRead.filter(
						(id) => id !== notificationId
					),
				}),
				() => {
					this.cache.clearAll();
					if (this.listRef.current) {
						this.listRef.current.forceUpdateGrid();
					}
				}
			);
		}, 10000);

			this.props.handleReadNotify(notificationId, url);
	};

	handleDelete = (notificationId) => {
		this.setState(
			(prevState) => ({
				notificationIDSpinnerDelete: [
					...prevState.notificationIDSpinnerDelete,
					notificationId,
				],
			}),
			() => {
				this.cache.clearAll();
				if (this.listRef.current) {
					this.listRef.current.forceUpdateGrid();
				}
			});
		setTimeout(() => {
			this.setState(
				(prevState) => ({
					notificationIDSpinnerDelete: prevState.notificationIDSpinnerDelete.filter(
						(id) => id !== notificationId
					),
				}),
				() => {
					this.cache.clearAll();
					if (this.listRef.current) {
						this.listRef.current.forceUpdateGrid();
					}
				}
			);
		}, 10000);

		this.props.handleDeleteNotify(notificationId);
	};

	formatDateTime = (timestamp) => {
		return CalendarHelper.notificationDate(
			moment(timestamp).format("YYYY"),
			moment(timestamp).format("MM"),
			moment(timestamp).format("DD"),
			moment(timestamp).format("HH"),
			moment(timestamp).format("mm")
		);
	};

	renderRow = ({ index, key, style, parent }) => {
		const {
			notifications,
		} = this.props;
		const notification = notifications[index];
		const { notificationIDSpinnerDelete, notificationIDSpinnerRead } = this.state;
		const isSpinnerDeleteActive = notificationIDSpinnerDelete.includes(notification.id);
		const isSpinnerReadActive = notificationIDSpinnerRead.includes(notification.id);

		return (
			<CellMeasurer
				key={key}
				cache={this.cache}
				parent={parent}
				columnIndex={0}
				rowIndex={index}
			>
				{({ measure }) => (
					<div style={style}>
						<div
							className={`notifications-container-list-item notifications-container-list-item-${notification.read ? "read" : "unread"
								}`}
							onClick={() => this.handleRead(notification.id, notification.url)}
							style={{ position: "relative", overflow: "hidden" }}
						>
							{isSpinnerReadActive && (
								<div
									style={{
										position: "absolute",
										top: 0,
										left: 0,
										width: "100%",
										height: "100%",
										background: "rgba(255, 255, 255, 0.8)",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										zIndex: 10,
									}}
								>
									<div className="spinner" />
								</div>
							)}
							<div className="notifications-container-list-item-title">
								{this.formatDateTime(notification.timestamp) +
									" - " +
									(notification.company_name ??
										notification.company_identifier)}
							</div>
							<div className="notifications-container-list-item-text">
								{notification.description}
								<div
									className="notifications-container-list-item-delete"
									onClick={(e) => {
										e.preventDefault();
										this.handleDelete(notification.id);
										e.stopPropagation();
									}}
								>
									{isSpinnerDeleteActive ? (
										<div
											className='spinner'
										/>
									) : (
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g fill="none" fillRule="evenodd">
												<path
													fill="none"
													d="M0 0h16v16H0z"
												/>
												<path
													d="M13.368 6.177L12.036 15.5h-8.19L2.494 6.045m3.362-4.474C5.857.98 6.405.5 7.082.5h1.836c.677 0 1.225.48 1.225 1.071M.5 2.107h15V4.25H.5zm5.821 4.286v6.964M9.68 6.393v6.964"
													stroke="#F85359"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</g>
										</svg>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
			</CellMeasurer>
		);
	};

	render() {
		const { notifications } = this.props;

		return (
			<AutoSizer>
				{({ width, height }) => (
					<List
						ref={this.listRef}
						width={width}
						height={height}
						rowCount={notifications.length}
						rowHeight={this.cache.rowHeight}
						deferredMeasurementCache={this.cache}
						rowRenderer={this.renderRow}
					/>
				)}
			</AutoSizer>
		);
	}
}

export default NotificationsListVirtualized;
