// Third-party libraries
import React from "react";
import Cookies from "universal-cookie";
import axios from "axios";

// Application-specific modules
import appConfig from "../appConfig";
import { fetchHeadersWithAuth } from "../../api/routes/api.route";
import { PaymentActive, PaymentMethod } from "../types";


class PaymentService extends React.Component {
  static cookies = new Cookies();

  static detailsMapper = (d) => {
    return !d ? null : ({
      reference: d.reference,
      entity: d.entity,
      expirationDate: d.expiration_date,
      waitingTime: d.waiting_time,
      phone: d.phone,
      url: d.url,
      type: d.type,
      id: d.id,
    });
  }

  static convertTypeIntoMethod = (type) => {
    return (type === PaymentActive.CC
      ? PaymentMethod.CC
      : type === PaymentActive.MB
        ? PaymentMethod.MB
        : PaymentMethod.MBWAY);
  };

  /**
   * Makes a call to the payment API.
   * @param {string} id - The payment identifier.
   * @param {PaymentMethod} method - The payment method (e.g., "MB", "MBWAY", "CC").
   * @param {string} phone - The phone number for MBWAY payment, if applicable.
   */
  static callPaymentApi = async ({id, method, total, phone}) => {
    try {
      const body = { id, method, total, phone }
      const response = await axios.post(
        `${appConfig.BASE_URL}/payment`, body,
        { headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")) }
      );
      //console.log('callPaymentApi', response);
      return {
        success: true, details: this.detailsMapper(response?.data)
      };
    } catch (error) {
      //console.error("Error fetching Payment API:", error);
      return { success: false, error: error.message };
    }
  };

  /**
   * Makes a call to get the payment state.
   * @param {string} id - The service identifier.
   */
  static getPaymentStatus = async (id) => {
    try {
      // const params = { id }
      const response = await axios.get(
        `${appConfig.BASE_URL}/payment/status/${id}`,
        { headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")) }
      );
      //console.log('[getPaymentState]', response);
      return { success: true, data: response?.data?.status };
    } catch (error) {
      //console.error("Error fetching getPaymentStatus:", error);
      return { success: false, error: error.message };
    }
  };

  /**
   * Makes a call to get the payment state.
   * @param {string} id - The payment fo identifier.
   * @param {"SUCCESS" | "ERROR" | "CANCEL"} status - The service identifier.
   * @param {string} sk
   */
  static updateCCState = async ({id, status, sk}) => {
    try {
      const body = {
        payment_id: id,
        status: status?.toUpperCase(),
        sk
      };
      const response = await axios.post(
        `${appConfig.BASE_URL}/payment/cc/update`, body,
        { headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")) }
      );
      return { success: true, data: response?.data };
    } catch (error) {
      console.error("Error fetching updateCCState:", error);
      return { success: false, error: error.message };
    }
  };
}

export default PaymentService;