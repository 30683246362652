import React, { Fragment } from "react";
import './LinkButton.scss';
import Helper from '../../../core/helper/helper';

/**
 * @param {Object} props
 * @param {string=} props.styleClass - (Optional) The CSS class for styling the button.
 * @param {string=} props.textClass - (Optional) The CSS class for styling the text.
 * @param {string=} props.valueText - (Optional) The text value to display.
 * @param {string=} props.valueLink - (Optional) The link value (URL).
 * @param {string=} props.icon - (Optional) The icon to display.
 * @param {boolean=} props.isMailTo - (Optional) Indicates whether the link should open a mail client (e.g., "mailto:" link).
 * @param {boolean=} props.isPhone - (Optional) Indicates whether the link should open a phone dialer for a phone number.
 * @param {boolean=} props.isMaps - (Optional) Indicates whether the link should open a map application.
 * @param {Function} props.onClick - A function to handle the click event.
 */
const LinkButton = (props) => {
  const { styleClass, textClass, valueText, icon, iconClass,
    valueLink, isMailTo, isPhone, isMaps, isDetailsCard } = props;

  const renderIcon = () => {
    if (icon) {
      return (
        <img
          src={icon}
          alt="icon-link"
          className={`me-1 ${iconClass ? iconClass : ''}`}
        />
      );
    }
    return <Fragment />;
  };

  const renderLink = () => {
    if (valueLink) {
      let externalLink = `mailto:${valueLink}`;
      if (!isMailTo) {
        externalLink = /^https?:\/\//.test(valueLink) ? valueLink : `http://${valueLink}`;
      }
      if (isPhone) {
        externalLink = `tel:${valueLink}`;
      }

      if (isMaps) {
        if(Helper.isIOS() || Helper.isIOSWebview()) {externalLink= `http://maps.apple.com/?q=${encodeURIComponent(valueLink)}`;}
        else{ externalLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(valueLink)}`;}
      }
      return (
        <a className="link-container d-flex" target="_blank" rel="noopener noreferrer" href={externalLink}>
          <span className={`link-container-span ${isDetailsCard ? "details" : ""} ${textClass || ''}`}>{valueText}</span>
        </a>
      );
    }

    return (
      <span className={`link-container-span ${textClass || ''}`}>{valueText}</span>
    );
  };

  return (
    <div className={styleClass} onClick={props.onClick}>
        {renderIcon()}
        {renderLink()}
    </div>
  );
}

export default LinkButton;