// React Imports
import React, { useEffect, useState } from 'react';
// Style
import './symptom_row.style.scss';
// Assets
import { GENERAL, SERVICE } from '../../../../assets/images/images';
import SelectComponent from '../selectComponent/SelectComponent';
import ScheduleService from '../../core/services/schedule.service';

const cLabels = {
  selectSpecialist: "Escolha um especialista:",
  placeholderSpecialist: "- Qualquer especialista -"
};

function SymptomRow(props) {
  // props
  const onExpertChangeProp = props.onExpertChange;
  const selectedOptionProp = props.selectedOption;

  // states
  const [idx] = useState(props.idx);
  const [active, setActive] = useState(props.service.active);
  const [symptom, setSymptom] = useState(props.service);
  const [selectedOption, setSelectedOption] = useState(props.selectedOption);
  const [payment, setPayment] = useState(props.service.schedule_payment_value);
  const [fixedDate, setFixedDate] = useState(
    props.service.schedule_fixed_start_enabled
      ? props.service.schedule_fixed_start
      : null
  );
  const [fixedEndDate, setFixedEndDate] = useState(
    props.service.schedule_fixed_start_enabled
      ? props.service.schedule_fixed_end
      : null
  );
  const [options, setOptions] = useState(props.options);

  // consts
  const symptomsUsers = symptom.users;

  useEffect(() => {
    setPayment(props.service.schedule_payment_value);
  }, [props.service.schedule_payment_value]);

  useEffect(() => {
    setFixedDate(props.service.schedule_fixed_start_enabled
      ? props.service.schedule_fixed_start
      : null);
    setFixedEndDate(props.service.schedule_fixed_start_enabled
      ? props.service.schedule_fixed_end
      : null);
  }, [props.service.schedule_fixed_start_enabled, props.service.schedule_fixed_start, props.service.schedule_fixed_end]);

  useEffect(() => {
    setActive(props.service.active)
  }, [props.service.active]);

  useEffect(() => {
    setSymptom(props.service);
  }, [props.service]);

  useEffect(() => {
    if (active && !selectedOptionProp && symptomsUsers.length === 1) {
      const expert = symptomsUsers[0];
      setSelectedOption(expert);
      onExpertChangeProp({ value: expert.id, label: expert.name });
    } else {
      setSelectedOption(selectedOptionProp);
    }
  }, [symptomsUsers, active, onExpertChangeProp, selectedOptionProp]);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const handlerClick = () => {
    setActive(!active);
    props.onClick();
  };

  const onSymptomObsChange = (symptom, value) => {
    props.onSymptomObsChange(symptom, value);
  }

  const activeStatus = (active) => {
    return <img className='symptoms-row-status' src={active ? SERVICE.serviceChecked : SERVICE.serviceNotChecked} alt="" />;
  };

  const renderSymptomObs = () => {
    if (active && symptom.obs_active) {
      return (
        <div className="observations-container mt-2 ms-3 me-3">
          <div className="observations-label normal-light-black">
            {symptom.obs_label}
          </div>
          <input
            type="text"
            maxLength="255"
            defaultValue={symptom.obs_value || ''}
            onChange={(e) => onSymptomObsChange(symptom, e.target.value)}
            className={`km-text-area smallwide`}
            placeholder=""
          />
        </div>
      );
    } else {
      return null;
    }
  }

  const renderExpert = () => {
    if (!(active && symptom.users && symptom.users.length > 0)) {
      return null;
    }
    return (
      <div className="observations-container mt-2 ms-3 me-3">
        <SelectComponent
          selectedOption={selectedOption ? { value: selectedOption.id, label: selectedOption.name } : null}
          onChange={props.onExpertChange}
          options={options}
          config={{
            isMulti: false,
            isClearable: true,
            autoFocus: false,
            isSearchable: false
          }}
          label={cLabels.selectSpecialist}
          placeholder={cLabels.placeholderSpecialist}
          classSelectLabel="normal-light-black"
          classSelectContainer="onSymptomListContainer"
        />
      </div>
    )
  }

  return (
    <li key={idx} className='symptoms-row-container py-2' >
      <div onClick={handlerClick} className="row-content px-3 justify-content-between">
        <div className="row-content px-0" style={{ width: 'unset' }}>
          {activeStatus(active)}
          <div className={`symptoms-row-text text-align-left inline-flex-start flex-wrap`}>
            {symptom?.name ? <div style={{ marginRight: '8px' }}>{symptom.name.toLowerCase()}</div> : 'Sem nome'}
            {symptom?.notAvailable && <span className="failure" > Vagas indisponíveis</span>}
          </div>
        </div>
        <div className={`symptoms-row-text text-align-left flex-end flex-nowrap`}>
          <div className='flex-wrap d-flex justify-content-end'>
            <div className="flex-wrap">
              {fixedDate && (
                <div className='px-1 text-nowrap'>
                  <img src={GENERAL.iconCalendarScheduled} alt='check-in-icon' className='me-1'/>
                  {ScheduleService.convertDateTimeToPTFormat(fixedDate)}
                </div>
              )}
              {fixedEndDate && (
                <div className='pt-1 px-1 text-nowrap'>
                  <img src={SERVICE.checkout} alt='check-in-icon'  className='me-1'/>
                  {ScheduleService.convertDateTimeToPTFormat(fixedEndDate)}
                </div>
              )}
            </div>
          </div>
            {payment && (
              <div className="ms-1 flex-center h-100">
                <div className='px-1 text-nowrap flex-center'>
                  {`${payment.toString().replace('.', ',')} €`}
                </div>
              </div>
            )}
          {symptom?.video_call &&
            <div className="flex-center" title='Possibilidade de Videochamada'>
              <img style={{ height: '16px', paddingLeft: '4px' }} src={GENERAL.iconVideoCall} alt="" />
            </div>
          }
        </div>
      </div>
      {renderSymptomObs()}
      {renderExpert()}
    </li>
  );
}

export default SymptomRow;
