import React, { Fragment } from "react";
import { GENERAL } from "../../../../assets/images/images";
import LinkButton from "../../../button/LinkButton/LinkButton";

/**
 * @param {Object} props - The props object.
 * @param {Company } props.company - The company object (or undefined).
 */
const CompanyAddressInfo = (props) => {
  const { company, isDark } = props;

  if (!company?.address) {
    return <Fragment />;
  }

  return (
    <div className="company-info d-inline-flex">
      <div className="info-icon me-2">
        <img
          src={GENERAL.iconLocation}
          alt="address-icon"
          className={isDark ? 'filter-white' : ''}
        />
      </div>
      <div className="info-text d-flex align-items-center">
        <LinkButton
          onClick={() => {}}
          styleClass="link-container"
          textClass="small"
          isMaps
          valueLink={company?.name + ", " + company?.address}
          valueText={company?.address || company?.town}
        />
      </div>
    </div>
  );
}

export default CompanyAddressInfo;