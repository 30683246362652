import React, { Fragment, useEffect, useState } from "react";
import Helper from "../../../core/helper/helper";
/**
 * @param {Object} props
 * @param {boolean} props.stepActive
 * @param {string} props.itemInfoTitle
 * @param {string} props.itemInfoSubtitle
 * @param {Funtion} props.onStepActive
 * @param {string} props.itemInfoId
 * @returns
 */
const ScheduleTopInformation = (props) => {
  const [stepActive, setStepActive] = useState(props.stepActive);
  const [itemInfoSubtitle, setitemInfoSubtitle] = useState(props.itemInfoSubtitle);
  const [itemInfoTitle, setitemInfoTitle] = useState(props.itemInfoTitle);
  const [itemInfoId, setitemInfoId] = useState(props.itemInfoId);
  const [randomTitle, setRandomTitle] = useState(Helper.newRandomString(Helper.printValue(props.itemInfoTitle)?.length - Helper.printValue(props.itemInfoTitle)?.slice(" ")[1]?.length));
  const [randomSubtitle, setRandomSubtitle] = useState(Helper.newRandomString(Helper.printValue(props.itemInfoSubtitle)?.length - Helper.printValue(props.itemInfoSubtitle)?.slice(" ")[1]?.length));
  const [hasServiceFixedDate, setHasServiceFixedDate] = useState(props.hasServiceFixedDate);

  useEffect(() => {
    setHasServiceFixedDate(props.hasServiceFixedDate);
  }, [props.hasServiceFixedDate]);

  useEffect(() => {
    setStepActive(props.stepActive);
  }, [props.stepActive]);

  useEffect(() => {
    setitemInfoSubtitle(props.itemInfoSubtitle);
    setRandomSubtitle(Helper.newRandomString(Helper.printValue(props.itemInfoSubtitle)?.length - Helper.printValue(props.itemInfoSubtitle)?.slice(" ")[1]?.length));
  }, [props.itemInfoSubtitle]);

  useEffect(() => {
    setitemInfoTitle(props.itemInfoTitle);
    setRandomTitle(Helper.newRandomString(Helper.printValue(props.itemInfoTitle)?.length - Helper.printValue(props.itemInfoTitle)?.slice(" ")[1]?.length));
  }, [props.itemInfoTitle]);

  useEffect(() => {
    setitemInfoId(props.itemInfoId);
  }, [props.itemInfoId]);

  const renderStepsContainer = () => {
    const steps = [1, 2, 3];
  
    const handleStepClick = (step) => {
      // Disable clicking on step 2 when hasServiceFixedDate (fixed date does not allow changing the date in step 2)
      if (hasServiceFixedDate && stepActive === 3 && step === 2) return;
      
      setStepActive(step);
      props.onStepActive(step);
    };
  
    return (
      <div className="steps-container mt-4">
        {steps.map((step, i) => (
          <div
            className={`step ${
              step === stepActive
                ? "active"
                : step > stepActive || (hasServiceFixedDate && stepActive === 3 && step === 2)
                ? "disabled"
                : ""
            }`}
            role="button"
            key={i}
            onClick={() => {
              if (!(hasServiceFixedDate && stepActive === 3 && step === 2)) {
                handleStepClick(step);
              }
            }}
          >
            {step}
          </div>
        ))}
      </div>
    );
  };
  

  const renderValue = (value, random) => {
    return (Helper.isLiteVersion() && !itemInfoId?.includes("int")) ? (
      <Fragment>
        <span>
          {(() => {
            const processedValue = Helper.printValue(value);
            if (processedValue.includes(" ") || processedValue.includes("-")) {
              return processedValue.split(" ")[0].split("-")[0];
            } else {
              return processedValue.slice(0, 2);
            }
          })()}
        </span>
        <span className='pixelized'>{random}</span>
      </Fragment>
    ) : (
      Helper.printValue(value)
    );
  };

  const renderTopDescription = () => {
    if (stepActive === 3) {
      return (<Fragment />);
    }

    return (
      <div className="schedule-service-description-container">
        <div className="service-description-text">Agendamento {renderValue(Helper.formatTitleIfBirthday(itemInfoSubtitle,false), randomSubtitle)}</div>
        <div className="service-param-info">{renderValue(Helper.formatTitleIfBirthday(itemInfoTitle,true), randomTitle)}</div>
      </div>
    );
  };

  return (
    <div className="top-information-container mt-5">
      {renderTopDescription()}
      {renderStepsContainer()}
    </div>
  );
};

export default ScheduleTopInformation;
