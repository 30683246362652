import React from 'react';
import Helper from '../../../core/helper/helper';
import Labels from '../../../variables/labels';

function CheckOutDate (props) {
  const { data: {bars, dateEnd, dateEndState, timeEnd}} = props;

  let dateClass = bars === 4 ? 'service-date-text active flex-start' : 'service-date-text flex-start';
    const renderDate = () => {
      if (bars >= 1 && bars < 4 && dateEnd) {
        return (
          <div className="service-date-estimated-container">
            <div className='estimated-date-label w-100 d-flex flex-start'> {Labels.estimatedDate} </div>
            <div className='estimated-date-text w-100 flex-start'> {dateEnd} </div>
          </div>
        );
      }
      return (
        <React.Fragment>
          {dateEndState} {timeEnd && timeEnd}
        </React.Fragment>
      );
    }

    return (
      <div className='service-equipment-data-row mx-0'>
        <img src={Helper.checkoutIcon(dateEnd, bars)} alt="" />
        <div className={dateEnd ? dateClass : 'service-date-text tbd'}>
          {renderDate()}
        </div>
      </div>
    );
}

export default CheckOutDate;