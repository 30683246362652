import React, { useCallback, useEffect, useState } from "react";
import CompanyAddressInfo from "../../../../../components/card/CompanyCard/CompanyCardInfo/CompanyAddressInfo";
import CompanyWebLinkInfo from "../../../../../components/card/CompanyCard/CompanyCardInfo/CompanyWebLinkInfo";
import ExpandedCompanyCard from "../../../../../components/card/CompanyCard/ExpandedCompanyCard";
import { GENERAL } from "../../../../../assets/images/images";
import Button from "../../../../../components/button/button";
import Emitter from "../../../../../core/services";
import { getCompanyIAgreeData } from "../../../../../api/routes/api.route";


/**
 * @param {Object} props - The component props.
 * @param {Company | undefined} props.company - The company data to display.
 */
const CurrentCompanyDetailsInfo = (props) => {
  const [company] = useState(props.company);
  const [expanded, setExpanded] = useState(false);

  const [modalIAgreeConfirmText, setModalIAgreeConfirmText] = useState("");
  const [replaceCompanyName, setReplaceCompanyName] = useState("");
  const [replaceVat, setReplaceVat] = useState("");
  const [replaceClient, setReplaceClient] = useState("");
  const [replaceItem, setReplaceItem] = useState("");
  const [replaceWork, setReplaceWork] = useState("");
  const [companyTerms, setCompanyTerms] = useState("");

  // useEffect(() => {
  //   setCompany(props.company);
  // }, [props.company]);

  useEffect(() => {
    setModalIAgreeConfirmText("Continuar");
  }, []);

  const setCompanyIAgreeData = useCallback((domain) => {
    if (!domain) return;
    getCompanyIAgreeData(domain).then((res) => {
      if (res) {
        if (res.client && res.client.length > 0) {
          const clientString = res.client.join(', ');
          setReplaceClient(clientString);
        }
        if (res.item && res.item.length > 0) {
          let itemString = res.item.join(', ');
          if (res.client) itemString = ', ' + itemString;
          setReplaceItem(itemString);
        }
        if (res.work && res.work.length > 0) {
          let workString = res.work.join(', ');
          if (res.item || res.client) workString = ', ' + workString;
          setReplaceWork(workString);
        }
        if (res.vat) {
          setReplaceVat(res.vat);
        }
        if (res.name) {
          setReplaceCompanyName(res.name);
        }
        if (res.terms) {
          setCompanyTerms(res.terms);
        }
      }
    }).catch((error) => {
      console.error("Error fetching company data: ", error.message);
    });
  }, [setReplaceClient, setReplaceItem, setReplaceWork, setReplaceVat, setReplaceCompanyName]);

  useEffect(() => {
    const domain = company?.domain?.replace('.qa', '');
    if (domain) setCompanyIAgreeData(domain);
  }, [company, setCompanyIAgreeData]);

  const iAgreeModal = (shouldRunFunctionOnClose = false, showClose = false) => {
    const details = {
      type:'iagree',
      confirmText: modalIAgreeConfirmText,
      replaceCompanyName,
      replaceVat,
      replaceClient,
      replaceItem,
      replaceWork,
      companyTerms
    };
    Emitter.emit('TOP_MODAL', details);
  }

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const renderExpandButton = () => {
    //return !company?.website && !company?.email && !company?.address ? null : 
    return (
      <React.Fragment>
        <div className="card-button-container" role={"button"} onClick={handleToggleExpand}>
          <div className="toggle-button">
            <img
              src={!expanded ? GENERAL.iconSmallArrowDown : GENERAL.iconSmallArrowUp}
              alt="toggle_section_icon"
              className={'filter-white'}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="company-details-container w-md-100">
      <div className="company-details-header">
        {renderExpandButton()}
        <ExpandedCompanyCard
          company={company}
          isDetailsCard={true}
          isDark={true}
          isCurrent={true}
          history={props.history}
        />
      </div>
      {!expanded ? null : (
        <>
          <div className="company-details-info d-flex flex-column">
            <CompanyAddressInfo company={company} isDark={true} />
            <CompanyWebLinkInfo company={company} isDark={true} />
          </div>
          <div className="i-agree-link mt-3" style={{ flexShrink: 0, display: 'flex', justifyContent: 'center' }}>
            <Button
              type="link"
              onClick={() => iAgreeModal(false, true)}
              className='link-container'
              textClass="small light"
              valueText={companyTerms? "Política de Privacidade e Condições Gerais" : "Política de Privacidade"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CurrentCompanyDetailsInfo;
