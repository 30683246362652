import { SERVICE } from "../../assets/images/images";
import { CATEGORIES } from "../../assets/variables/variables";
import Labels from "../../variables/labels";
import LocalData from '../localData';
import dateFormat from "date-format";
import AgeCalculator from './ageCalculator';
import appConfig from "../appConfig";
import Cookies from 'universal-cookie';
import CssFilterConverter from 'css-filter-converter';
import { CompanyMapper } from "../models/company.model";
import { CookiesLabels } from "../../variables/cookies.labels";

export default class Helper {
  static toastThemeOptions = {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };
  static toastThemeVideoOptions = {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    className: "toast-welcome",
  };
  static toastLiteVersionOpenned = false;
  static toastOptions = {
    position: "top-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: null,
    closeButton: true,
    onClose: () => {
      Helper.toastLiteVersionOpenned = false;
    },
  };
  static toastOptionsBV = {
    position: "top-right_BV",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: null,
    closeButton: true,
    onClose: () => {
      Helper.toastLiteVersionOpenned = false;
    },
  };
  static appVersion = LocalData.appVersion;
  static deviceInfo = Helper.getDeviceInfo;

  // Helper Config
  static cookies = new Cookies();
  static cookiesConfig = { ...LocalData.cookiesConfig, maxAge: 31556926 };
  static cookiesLiteConfig = LocalData.cookiesConfig;
  static saveConfig = (config) => {
    LocalData.configData = {
      ...config,
      company_id: config.company_id,
      labels: {
        ...config?.labels,
        item_label:
          !config.labels?.item_label || config?.labels?.item_label === ""
            ? "Item"
            : config?.labels?.item_label,
      },
      companyDetails: CompanyMapper.fromCompanyDto(config?.company_details),
    };
  };
  static itemLabel = () => this.getConfig().labels.item_label;
  static itemPronoun = () => (Helper.isFeminine(Helper.itemLabel()) ? "uma" : "um");
  static itemPronoun1 = () => (Helper.isFeminine(Helper.itemLabel()) ? "A" : "O");
  static itemPronoun2 = () => (Helper.isFeminine(Helper.itemLabel()) ? "sua" : "seu");
  static getConfig = () => LocalData.configData;
  static getServerLabels = () => this.getConfig().labels;
  static itemFieldsToAdd = () => LocalData.configData.item_fields_config_sign_up;
  static mergeItemFieldsConfig = (fields) => {
    return fields.map((field) => ({
      ...field,
      type: LocalData.configData?.item_fields_config_edit?.find((f) => f.name === field.key)?.type,
      options: LocalData.configData?.item_fields_config_edit
        ?.find((f) => f.name === field.key)
        ?.values?.map((v) => ({ value: v, label: v })),
    }));
  };

  static isAuto = () => this.getConfig().icons === "AUTO";
  static isTech = () => this.getConfig().icons === "TECH";
  static icons = () => {
    if (
      this.getConfig().icons &&
      this.getConfig().icons !== "" &&
      this.getConfig().icons !== "NULL"
    ) {
      return this.getConfig().icons;
    }
    return "DEFAULT";
  };
  static vertical = () => {
    if (
      this.getConfig().vertical &&
      this.getConfig().vertical !== "" &&
      this.getConfig().vertical !== "NULL"
    ) {
      return this.getConfig().vertical;
    }
    return "DEFAULT";
  };
  static companyName = () => (Helper.isDemo() ? "Empresa Demo" : LocalData.configData.company_name);
  static companyDomain = () =>
    LocalData?.configData?.domain ?? window.location.hostname.replace(".keymaster.pt", "");
  static companyLogo = () => Helper.urlCompanyImage(LocalData.configData.logo);
  static companiesLength = () => LocalData.companies?.length;
  static whatsappNumber = () => LocalData.configData?.whatsapp_number;
  static whatsappLink = () => `https://wa.me/${this.whatsappNumber()}`;
  static playstoreLink = "https://keymaster.pt/apps/play/store/cliente";
  static welcomePageLink = () =>
    window.location.hostname.includes("qa") || window.location.hostname.includes("localhost")
      ? "https://asuaempresa.qa.keymaster.pt/clientwelcome"
      : "https://asuaempresa.keymaster.pt/clientwelcome";
  static welcomeLogoPath = "assets/images/animations/welcome-logo-animation.json";
  static appName = () => this.getConfig().app_name;
  static titleServicesPage = () => `${Labels.servicesPage} | ${Helper.appName()}`;
  static titleServiceDetailsPage = () => `${Labels.serviceDetailsPage} | ${Helper.appName()}`;
  static titleItemDetailsPage = () => `${Labels.equipmentDetailsPage} | ${Helper.appName()}`;
  static titleProfilePage = () => `${Labels.profilePage} | ${Helper.appName()}`;
  static titleFeedbackPage = () => `${Labels.feedbackPage} | ${Helper.appName()}`;
  static titlePaymentPage = () => `${Labels.paymentPage} | ${Helper.appName()}`;
  static isLiteVersion = () => window.location.pathname.toString().endsWith("-lite") || window.location.pathname.toString().includes("schedule-lite");
  static isHomepage = () =>
    window.location.pathname.toString().includes("services") && !this.isLiteVersion();
  static isHomepageLite = () =>
    window.location.pathname.toString().includes("services-lite");
  static isServiceDetails = () =>
    window.location.pathname.toString().includes("service-details") && !this.isLiteVersion();
  static isServiceDetailsLite = () =>
    window.location.pathname.toString().includes("service-details-lite");
  static isSchedulePage = () => window.location.pathname.toString().includes("schedule");
  static isPaymentPage = () => window.location.pathname.toString().includes("payment");
  static isHelpDeleteAccount = () =>
    window.location.pathname.toString().endsWith("help-delete-account");
  static isInternalTeamCompany() {
    const domain = Helper.companyDomain();
    return (
      domain.includes("agendamentostelma") ||
      domain.includes("agendamentosfilipa") ||
      domain.includes("agendamentosformacao")
    );
  }
  static isDemo(companyName) {
    return companyName
      ? companyName.includes("nomecomercialdasuaempresa")
      : Helper.companyDomain().includes("nomecomercialdasuaempresa");
  }
  static hasClientIDForLogin = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("cli");
  };
  static isFO = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("FO") || urlParams.get("fo");
  };
  static getTermsAndConditions = () =>
    this.isAuto() ? Labels.termsConditionsAuto : Labels.termsConditionsTech;
  static getCookiesMessage = () =>CookiesLabels.cookiesMessage.replace("{0}", "Keymaster");
  static getCompanyWebsite = () => `https://${window.location.hostname}`;
  static userEmail = () => LocalData.userData?.email || LocalData.liteData?.email;
  static userPhone = () => LocalData.userData?.phone || LocalData.liteData?.phone;

  static getLiteRegisterUrl = (embedded=false) => {
    const baseLink = this.welcomePageLink();
    let queryParams = "";
    if (this.userPhone()) {
      queryParams = `?phone=${encodeURIComponent(this.userPhone())}`;
    } else if (this.userEmail()) {
      queryParams = `?email=${encodeURIComponent(this.userEmail())}`;
    }
    const additionalParam = "fromLite=true";
    if (queryParams.length > 0) {
      queryParams += `&${additionalParam}`;
    } else {
      queryParams = `?${additionalParam}`;
    }
    if(embedded) {queryParams += `&embedded=true`;}

    return LocalData.liteData?.is_client
      ? `${baseLink}/login${queryParams}`
      : `${baseLink}/registo${queryParams}`;
  };

  static getLiteRegisterButton = () => "Continuar";
  //LocalData.liteData?.is_client ? Labels.button.loginButton : Labels.button.liteRegisterButton;

  // Helper Icons
  static sidebarEquipmentIcon = () => {
    return require(`assets/images/verticais/icons/${this.icons()}/iconequip.svg`);
  };
  static equipmentIcon = () => {
    return require(`assets/images/verticais/icons/${this.icons()}/equip.svg`);
  };
  static showCategoryIcon = (id) => id && this.printCategoryIcon(id) && this.isAuto();
  static checkinDisableIcon = () =>
    this.isAuto() ? SERVICE.checkinDisable : SERVICE.checkinDisableTech;

  static checkinIcon = (schedule, state, dateStart) => {
    if (!dateStart || state === 1 || state === 5) {
      return SERVICE.checkin;
    }

    if (state > 1 && state < 5) {
      return SERVICE.serviceActive;
    }

    if (state < 1 || schedule) {
      return SERVICE.scheduled;
    }
    return SERVICE.checkin;
  };

  static checkoutIcon = (dateEnd, state) => {
    if (state === 1) {
      return SERVICE.serviceActive;
    }

    if (!dateEnd) {
      return SERVICE.toBeDefine;
    }

    if (state === 3) {
      return SERVICE.scheduledOut;
    }
    return SERVICE.checkout;
  };

  static defaultImage = () => require(`assets/images/verticais/icons/${this.icons()}/equip.svg`);
  static urlItemImage = (url) =>
    url ? `${appConfig.BASE_URL}/photos/item/${url}` : this.defaultImage();
  static urlItemImageByID = (id, company_id, userId = LocalData.userDetails?.userId) =>
    id && company_id && userId
      ? `${appConfig.BASE_URL}/photos/item/id/${id}?userId=${userId}&companyId=${company_id}`
      : this.defaultImage();
  static urlWorkImage = (url) =>
    url ? `${appConfig.BASE_URL}/photos/work/${url}` : this.defaultImage();
  static urlFullWorkImage = (url) =>
    url ? `${appConfig.BASE_URL}/photos/work/full/${url}` : this.urlItemImage(url);
  static urlCompanyImage = (url) => (url ? `${appConfig.BASE_URL}/photos/company/${url}` : "");
  static x64Image = (code) => (code ? `data:image/png;base64, ${code}` : this.defaultImage());
  static isVideo = (type) => type === "VIDEO";

  // Helper fields
  static printValue = (value) => (value ? value : '\u00A0');
  static printCategoryIcon = (id) => CATEGORIES.find((value) => value.id === +id)?.icon;
  static printDate = (date) => (date ? date : Labels.undefinedDate);
  static printRating = (feedback) => (feedback ? feedback.classification : 0);
  static printObserv = (feedback) => (feedback ? feedback.observation : "");
  static initialItemState = (item, isLiteVersion, isAppointment) => {
    if (item) {
      return {
        ...item,
        image: isLiteVersion
          ? isAppointment
            ? item.photo
              ? item.image
              : this.urlItemImageByID(item.id, this.getConfig().company_id)
            : item.image
          : this.urlItemImage(item.image),
      };
    }
    return { id: null, image: this.defaultImage(), title: "", subtitle: "" };
  };
  static initialItemDetailsState = (item, isLiteVersion, isAppointment) => {
    if (item) {
      return {
        ...item,
        image: isLiteVersion
          ? isAppointment
            ? this.x64Image(item.photo)
            : this.x64Image(item.image)
          : this.urlItemImage(item.image),
      };
    }
    return { id: null, image: this.defaultImage(), title: "", subtitle: "" };
  };

  static findFieldIndex = (fields, field) => fields.findIndex((el) => el.name === field.name);
  static setFieldValues = (values) =>
    values.map((value, i) => {
      return { value: i, label: value };
    });

  static buildInputSelectValues = () => {
    const values = this.itemFieldsToAdd().filter((field) => field.type === "inputselect")[0].values;
    return this.setFieldValues(values);
  };

  static buildInputSelectFields = () => {
    let selectFields = this.itemFieldsToAdd().filter((field) => field.type === "inputselect");
    return selectFields.map((field) => this.setFieldValues(field.values));
  };

  static getNextInternalEquipID() {
    const existingIntItems = LocalData.equipmentsData.filter(item => item.id.startsWith('int'));
    return `int${existingIntItems.length}`;
  } 

  static buildLiteEquipmentData = (() => {
    return (fields, token, tokenCal) => {
      const configData = LocalData.configData;
      const titleTemplate = configData?.item_title;
      const subtitleTemplate = configData?.item_subtitle;
      function replacePlaceholders(template, fields) {
        return template.replace(/{(\w+)}/g, (_, placeholder) => {
            const field = fields.find(f => f.name === placeholder);
            return field ? field.selectedValue : '';
        });
      }
      // Replace placeholders in the title and subtitle
      const title = replacePlaceholders(titleTemplate, fields);
      const subtitle = replacePlaceholders(subtitleTemplate, fields);

      const nextId = Helper.getNextInternalEquipID(LocalData.equipmentsData);
      let newItem = {
        id: nextId,
        title: title,
        subtitle: subtitle,
        specs: {}
      };
      fields.forEach(field => {
        newItem.specs[field.name] = field.selectedValue;
      });
      const tempEquips = [...LocalData.equipmentsData, newItem];
      LocalData.equipmentsData = tempEquips;
      if (token) { LocalData.equipmentsToken = token;}
      if (tokenCal) { LocalData.equipmentsTokenCal = tokenCal;}
      if(!token && !tokenCal) LocalData.equipmentsToken = 0;
    };
  })();
  
  static checkLiteTokenChange = () => {
    const query = window.location.search;
    const token = new URLSearchParams(query).get('token');
    const token_cal = new URLSearchParams(query).get('token_cal');    
    if (LocalData.equipmentsToken === '0' && !token && !token_cal) {
      return false;
    }
    if (LocalData.equipmentsToken !== '0' && !token && !token_cal) {
      return true;
    }
    if ((token && token === LocalData.equipmentsToken) || (token_cal && token_cal === LocalData.equipmentsTokenCal)) {
      return false;
    } else {
      return true;
    }
  }

  static buildEquipmentSpecsBody = (fields) => {
    const specsData = {};
    fields.forEach((field) => {
      specsData[field.name] =
        field.type === "inputselect" ? field.selectedValue?.label : field.selectedValue;
    });
    return { ...specsData };
  };
  static hasSpecsEmpty = (fields) => {
    let isEmpty = true;
    fields.forEach((field) => {
      isEmpty = field.selectedValue ? false : isEmpty;
    });
    return isEmpty;
  };

  static requiredFields = (fields) => fields.filter((field) => field.required);

  static hasRequiredFieldsEmpty = (fields) => {
    let isEmpty = false;
    const requiredFields = this.requiredFields(fields);
    requiredFields.forEach((field) => {
      isEmpty = field.selectedValue ? isEmpty : true;
    });
    return isEmpty;
  };

  static hasValidFields = (fields) => {
    if (this.requiredFields(fields).length) {
      return !this.hasRequiredFieldsEmpty(fields);
    } else {
      return !this.hasSpecsEmpty(fields);
    }
  };

  // Helper Fields Utils
  static setSelectListValuesCountry = (values) =>
    values.map((value) => {
      return { value: value.id, label: value.country };
    });
  static isSelectFieldEmpty = (field) => !field || (!field?.selectedValue && field?.required);

  // eslint-disable-next-line no-undef
  static emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  static testEmailRegex = (value) => {
    return this.emailRegex.test(value);
  };

  static passwordRegex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])[a-zA-Z0-9\\S]{8,}";
  static testPasswordRegex = (value) => {
    const regex = new RegExp(this.passwordRegex);
    return regex.test(value);
  };

  static testContactRegex = (value) => {
    const regex = /^((\+351|00351|351)?)(2\d{1}|(9(3|6|2|1)))\d{7}$/;
    return regex.test(value);
  };
  static checkVatNumber = (nif) => {
    let c;
    let checkDigit = 0;
    if (nif && nif.length === 9) {
      c = nif.charAt(0);
      if (c === "1" || c === "2" || c === "3" || c === "5" || c === "6" || c === "8" || c === "9") {
        checkDigit = c * 9;
        for (let i = 2; i <= 8; i++) {
          checkDigit += nif.charAt(i - 1) * (10 - i);
        }
        checkDigit = 11 - (checkDigit % 11);
        if (checkDigit >= 10) {
          checkDigit = 0;
        }
        if (checkDigit === +nif.charAt(8)) {
          return true;
        }
      }
    }
    return false;
  };

  static buildItemTitle = (specs) => {
    const { add_item_title } = this.getConfig();
    const titleProps = add_item_title?.replaceAll("{", "").replaceAll("}", "").split(" ");
    let title = "";
    if (titleProps) {
      const titleSpecs = specs.filter((s) => titleProps.includes(s.key));
      title = titleProps.reduce(
        (acc, prop) =>
          acc
            ? `${acc} ${titleSpecs.filter((s) => s.key === prop)[0]?.value}`
            : titleSpecs.filter((s) => s.key === prop)[0]?.value,
        0
      );
    }
    return title;
  };
  static buildNewItemTitle = (specs) => {
    const { add_item_title } = this.getConfig();
    const itemTitle = add_item_title?.replaceAll("{", "").replaceAll("}", "").split(" ");
    let title = "";
    if (itemTitle) {
      itemTitle.forEach(
        (prop) =>
          (title = prop && specs[prop] ? (title ? `${title} ${specs[prop]}` : specs[prop]) : title)
      );
    }
    return title;
  };

  static getItemRowValue = (row) => row.title || row.number || row.email || row.code;

  static formatTitleIfBirthday = (nonEditedString, isTitle) => {
    const fieldsToSearch = isTitle 
                          ? LocalData.configData?.item_title
                          : LocalData.configData?.item_subtitle; // Example: "{marca} {modelo}"
    const fieldConfig = LocalData.configData?.item_fields_config_edit; // Example: [{ name: "marca", type: "text" }, { name: "client_age", type: "birthday" }]
    if (!fieldsToSearch || !fieldConfig) {
      return nonEditedString;
    }
    const fieldNames = fieldsToSearch.match(/{\w+}/g)?.map(field => field.replace(/[{}]/g, "")) || [];

    // If there's more than one field, return the title as is
    if (fieldNames.length > 1) {
      return nonEditedString;
    }
    const fieldName = fieldNames[0];
    const field = fieldConfig.find(item => item.name === fieldName);

    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Matches dd/MM/yyyy format
    if (field?.type === "birthday" && dateRegex.test(nonEditedString)) {
      return Helper.buildAgeInfo(nonEditedString, "birthday", true);
    }   
    return nonEditedString;
  }

  static formatSubtitleIfBirthday = (title) => {
    return title;
  }

  static buildAgeInfo = (date, type, isShort=false) => {
    if (!date) {
      return;
    }
    if (type !== "birthday") {
      return;
    }
    const dateFormatted = typeof date === "string" ? date : dateFormat("dd/MM/yyyy", date);

    const years = AgeCalculator.calculateYears(date);
    if (years >= 1) {
      return `${years} ${years > 1 ? "anos" : "ano"} ${isShort ? '' : `(${dateFormatted})`} `;
    }

    const months = AgeCalculator.calculateMonths(date);
    if (months >= 1) {
      return `${months} ${months > 1 ? "meses" : "mês"} ${isShort ? '' : `(${dateFormatted})`} `;
    }
    const days = AgeCalculator.calculateDays(date);
    return `${days} ${days !== 1 ? "dias" : "dia"} ${isShort ? '' : `(${dateFormatted})`} `;
  };

  // Helper Utils
  static objectHasData = (obj) => obj && Object.keys(obj).length !== 0;
  static listNotEmpty = (list) => list && list.length;
  static listIsEmpty = (list) => !list || (list && !list.length);
  static hasNoServices = (arrayOfLists, showcaseItem) =>
    (arrayOfLists.every((list) => this.listIsEmpty(list)) && !this.isLiteVersion()) ||
    (!showcaseItem && this.isLiteVersion());
  static clone = (obj) => JSON.parse(JSON.stringify(obj));
  static listyHasPropValue = (list, prop, type) =>
    list ? (list[type] ?? []).some((i) => i[prop]) : false;
  static countProgressServices = (progressWorks, showcaseItem) =>
    (!showcaseItem && this.isLiteVersion()) ? 0 : (progressWorks && !this.listIsEmpty(progressWorks) ? progressWorks.length : 0);
  static countScheduleServices = (scheduleWorks, showcaseItem) =>
    (!showcaseItem && this.isLiteVersion()) ? 0 : (scheduleWorks && !this.listIsEmpty(scheduleWorks) ? scheduleWorks.length : 0);
  static countToFeedbackServices = (completedWorks, showcaseItem) =>
    (!showcaseItem && this.isLiteVersion()) ? 0 : (completedWorks && !this.listIsEmpty(completedWorks) ? completedWorks.filter((s) => !s.feedback).length : 0);

  // Helper Service
  static isServiceCompleted = (stateBars) => stateBars === 5;
  static observationCommentLabel = (type) =>
    type === "internal" ? Labels.servicesInternalCommentLabel : Labels.servicesClientCommentLabel;
  static createLinksInText = (text) => {
    const urlRegex = /https?:\/\/[^\s/$.?#].[^\s]*(?=<|$)|https?:\/\/[^\s/$.?#].[^\s]*/g;
    // Replace URLs in the text with clickable links and split the elements by break lines
    const textWithLinks = text.replace(/<br>/g, " <br> ").replace(urlRegex, (url) => {
      return `<span><a href="${url}" target="_blank">${url}</a><span/>`;
    });

    return textWithLinks;
  };

  // media file accept format
  //static mediaFileAccept = "image/gif,.jpg,.jpeg,.png, video/mp4,video/x-m4v,video/*";
  static mediaFileAccept =
    "image/jpeg,.jpg,.jpeg,image/png,.png,video/mp4,.mp4,video/quicktime,.mov";
  //static documentFileAccept = "application/pdf,.csv,.txt, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
  static documentFileAccept =
    "audio/mp3,.mp3,audio/mpeg,application/pdf,.pdf,application/msword,.doc,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx,application/vnd.ms-excel,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx,text/plain,.txt,application/zip,.zip,application/x-zip-compressed";

  static updateFilesAccept = (data) => {
    this.mediaFileAccept = data.media;
    this.documentFileAccept = data.doc;
  };
  static hideUploadButton = (serviceState, type) => {
    return serviceState?.bars === 5 || type !== "customer";
  };
  static showUploadButton = (serviceState, type) => {
    return serviceState?.bars < 5 && type === "customer" && !this.isLiteVersion();
  };
  static generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      // Set the playsInline attribute for iOS Safari
      video.playsInline = true; // for iOS
      // this is important
      video.autoplay = true;
      video.muted = true;
      video.currentTime = 2;
      video.src = URL.createObjectURL(file);

      video.addEventListener("loadedmetadata", () => {
        // Adjust the canvas size to your desired thumbnail dimensions
        const thumbnailWidth = 200;
        const thumbnailHeight = (video.videoHeight / video.videoWidth) * thumbnailWidth;
        const canvas = document.createElement("canvas");
        canvas.width = thumbnailWidth;
        canvas.height = thumbnailHeight;
        const ctx = canvas.getContext("2d");
        // Wait for the video to play for a moment
        video.onplay = () => {
          setTimeout(() => {
            ctx.drawImage(video, 0, 0, thumbnailWidth, thumbnailHeight);
            // Pause the video
            video.pause();
            // Convert the canvas to a Blob (image)
            canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.8);
          }, 1000); // Adjust the delay as needed
        };

        // Start playing the video
        video.play();
      });
    });
  };
  static generateImageThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const image = new Image();

      image.src = URL.createObjectURL(file);

      image.onload = () => {
        let ctx = canvas.getContext("2d");

        // Adjust the canvas size to your desired thumbnail dimensions
        const thumbnailWidth = 200;
        const thumbnailHeight = (image.height / image.width) * thumbnailWidth;
        canvas.width = thumbnailWidth;
        canvas.height = thumbnailHeight;

        ctx.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight);

        // You can adjust the quality by changing the second parameter (0.9)
        canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.8);
      };
    });
  };

  // Notifications
  static messageNotification = () => 'Mensagens';
  static getToastTitle = (opened, type) =>
    !opened && type === "message" ? Helper.messageNotification() : Labels.notifications.title[type];

  // LinktoAppStore
  static linkToAppStore = () => {
    return Helper.isIOS()
      ? "https://www.keymaster.pt/apps/ios/store/cliente"
      : "https://www.keymaster.pt/apps/play/store/cliente";
  };

  // Android
  static isAndroid = () => {
    return Helper.isAndroidBrowser() || Helper.isAndroidWebview();
  };
  static isAndroidBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android/i.test(userAgent);
  };
  static isAndroidWebview = () => {
    const userAgent = window.navigator.userAgent;
    // Check for specific WebView properties
    return (
      userAgent.includes("AndroidAppCustomerMobile") ||
      userAgent.includes("AndroidAppCustomerTablet") ||
      userAgent.includes("wv")
    );
  };

  // iOS
  static isIOS = () => {
    return Helper.isIOSBrowser() || Helper.isIOSWebview();
  };

  static isIOSBrowser = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIOSUA = /iphone|ipad|ipod/.test(userAgent);
    const isTouchScreen =
      "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    return isIOSUA || (isTouchScreen && navigator.platform === "MacIntel");
  };

  static isIOSWebview = () => {
    // Check for specific WebView properties
    const userAgent = window.navigator.userAgent;
    return userAgent.includes("iOSAppCustomerMobile") || userAgent.includes("iOSAppCustomerTablet");
  };

  static isWebview = () => {
    return this.isAndroidWebview() || this.isIOSWebview();
  };

  static hidePlayStore = () => {
    return (
      !Helper.playstoreLink ||
      Helper.isAndroidWebview() ||
      Helper.isIOS() ||
      Helper.isIOSWebview() ||
      !Helper.isAndroidBrowser() ||
      Helper.isHelpDeleteAccount()
    );
  };

  static formatDistance = (distanceInMeters) => {
    if (distanceInMeters && typeof distanceInMeters === "number") {
      if (distanceInMeters < 1000) {
        return ` - ${distanceInMeters?.toFixed(1)} m`;
      } else {
        const distanceInKilometers = distanceInMeters / 1000;
        return ` - ${distanceInKilometers.toFixed(1)} km`;
      }
    } else {
      return undefined;
    }
  };

  static memorizeCurrentCompany = () => {
    const companyDomain = Helper.companyDomain();
    if (!companyDomain.includes("asuaempresa")) {
      Helper.cookies.set("kssreturncompany", companyDomain, Helper.cookiesLiteConfig);
    }
  };
  static memorizeReturnToAppointment = () => {
    const urlParamAppointment = new URLSearchParams(window.location.search).get("openAppointment");
    if (urlParamAppointment) {
      Helper.cookies.set("openAppointment", true, Helper.cookiesLiteConfig);
    }
  };
  static logout(errorDoNotRedirect = false) {
    Helper.cookies.remove("sessionToken", Helper.cookiesConfig);
    Helper.cookies.remove("liteToken", Helper.cookiesLiteConfig);
    Helper.cookies.remove("liteEmail", Helper.cookiesLiteConfig);
    Helper.cookies.remove("litePhone", Helper.cookiesLiteConfig);
    Helper.cookies.remove("userId", Helper.cookiesLiteConfig);
    Helper.cookies.remove("isCalToken", Helper.cookiesLiteConfig);
    Helper.cookies.remove("prevCompanyDomain", Helper.cookiesLiteConfig);
    LocalData.reset();
    if (!errorDoNotRedirect) {
      Helper.memorizeCurrentCompany(); //Return to this company after login
      Helper.memorizeReturnToAppointment(); //Return to createAppointment after login
    } else {
      Helper.cookies.remove("kssreturncompany", Helper.cookiesLiteConfig);
      Helper.cookies.remove("openAppointment", Helper.cookiesLiteConfig);
    }
    window.location.href = Helper.cookies.get("openAppointment")
      ? `${Helper.welcomePageLink()}/registo`
      : `${Helper.welcomePageLink()}/login`;
  }

  static logoutFromLite = () => {
    Helper.memorizeCurrentCompany(); //Return to this company after login
    Helper.memorizeReturnToAppointment(); //Return to createAppointment after login
    window.location.href = Helper.getLiteRegisterUrl();
  };

  static isFeminine(word) {
    // List of common feminine suffixes in Portuguese
    const feminineSuffixes = [
      "a", // Most words ending in 'a' are feminine
      "ção", // Common for feminine nouns
      "ade", // Common for feminine nouns
      "eza", // Common for abstract nouns, which are usually feminine
      "ice", // Common for feminine nouns
      "isse", // Common for feminine nouns
      "ia", // Common for feminine nouns
      // Add more suffixes as you see fit
    ];

    // Extract the ending of the word for comparison, considering the longest common suffixes
    const ending = word.toLowerCase().slice(-4); // Consider the last four characters for broader matches
    const shorterEnding = word.toLowerCase().slice(-3); // Intermediate check
    const shortestEnding = word.toLowerCase().slice(-2); // Shortest common suffixes
    const singleEnding = word.toLowerCase().slice(-1); // Single character suffix

    // Check if the word ends with a feminine suffix
    return feminineSuffixes.some(
      (suffix) =>
        ending.endsWith(suffix) ||
        shorterEnding.endsWith(suffix) ||
        shortestEnding.endsWith(suffix) ||
        singleEnding.endsWith(suffix)
    );
  }

  static hexToFilter = (val) => {
    return CssFilterConverter.hexToFilter(val)?.color;
  };

  static newRandomString = (size) => {
    // Define os caracters que podem aparecer na string aleatória
    const caracters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    // Variável para armazenar a string aleatória
    let result = "";

    // Loop para escolher caracters aleatórios da string de caracters
    for (let i = 0; i < size; i++) {
      // Escolhe um caractere aleatório de 'caracters'
      const randomIndex = Math.floor(Math.random() * caracters.length);
      result += caracters.charAt(randomIndex);
    }

    return result;
  };

  static addOrUpdateSearchParam = (urlString, paramName, paramValue) => {
    try {
      // Create a new URL object
      const url = new URL(urlString);
      
      // Add or update the search parameter
      url.searchParams.set(paramName, paramValue);
      //console.log('addOrUpdateSearchParam', url);
      // Return the updated URL string
      return url;
    } catch (error) {
      console.error('Invalid URL:', error);
      return null;
    }
  };
}