import React, { useEffect, useState } from 'react';
import classNames from "classnames";
import InputField from '../../components/inputField/inputField';
import Helper from '../../core/helper/helper';
import { PaymentLogo } from './PaymentLogo';
import PaymentStateDetails from './PaymentStateDetails';
import { PaymentActive, PaymentMethod, PaymentState } from '../../core/types';
import Labels from '../../variables/labels';

const PaymentMethods = (/** @type { method, methods, payment, isLoading, onPayment, onMethod } */ props) => {
  // State to track the selected payment method and the phone number for MBWAY
  const [selectedMethod, setSelectedMethod] = useState(props.method);
  const [payment, setPayment] = useState(props.payment);
  const [, setIsLoading] = useState(props.isLoading);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [prefix] = useState('+351');
  const [isButtonDisabled, setIsButtonDisabled] = useState(!props.payment?.details?.type);
  const [isValidating, setIsValidating] = useState(props.payment?.urlParams?.status === "success" && props.payment?.state === PaymentState.PENDING && props.payment?.details?.type === PaymentActive.CC);


  useEffect(() => {setSelectedMethod(props.method)}, [props.method]);
  useEffect(() => {setIsLoading(props.isLoading)}, [props.isLoading]);
  useEffect(() => {
    setPayment(props.payment);

    const isPending = props.payment?.state === PaymentState.PENDING;
    const validating = props.payment?.urlParams?.status === "success" && isPending && props.payment?.details?.type === PaymentActive.CC;
    const payed = props.payment.state === PaymentState.PAYED;
    const mbwayInvalid = selectedMethod === PaymentMethod.MBWAY && (!Helper.testContactRegex(phoneNumber) || (isPending && props.payment?.details?.type === PaymentActive.MBWAY));
    const mbInvalid = selectedMethod === PaymentMethod.MB && isPending && props.payment?.details?.type === PaymentActive.MB;

    // console.log('selectedMethod', selectedMethod);
    // console.log('payed', payed);
    // console.log('validating', validating);
    // console.log('mbwayInvalid', mbwayInvalid);
    // console.log('mbInvalid', mbInvalid);
    // console.log('DISABLING', !selectedMethod || payed || validating || mbwayInvalid || mbInvalid);
    
    setIsValidating(validating);
    setIsButtonDisabled(!selectedMethod || payed || validating || mbwayInvalid || mbInvalid || props.isLoading);
  }, [props.payment, selectedMethod, phoneNumber, props.isLoading]);

  // Handle selecting a payment method
  const handleSelectMethod = (method) => {
    setSelectedMethod(method);
    props.onMethod(method);
    if (method === 'MBWAY') {
      setIsButtonDisabled(phoneNumber === "");  // Ensure button is disabled until phone number is provided
    } else {
      setIsButtonDisabled(isValidating || payment.state === PaymentState.PAYED);  // Enable for other methods
    }
  };

  // Handle phone number input for MBWAY
  const handlePhoneChange = (event) => {
    const value = event.target.value;
    setPhoneNumber(value);
    setIsButtonDisabled(selectedMethod === 'MBWAY' && !Helper.testContactRegex(value)); // Only disable if method is MBWAY and no phone number
  };

  // Handle the action when the user clicks "Continuar"
  const handleContinuePayment = () => {
    setIsButtonDisabled(true);
    if (selectedMethod === "MBWAY") {
      props.onPayment(selectedMethod, {phone: `${prefix}${phoneNumber}`});
    } else {
      props.onPayment(selectedMethod);
    }
  };

  /* ###################
  ** RENDER *
  /* ################## */

  const renderMB = () => {
    return (props.methods?.includes("MB") && (
      <div className="method" >
        <label className='inline-flex-start w-100' role='button'>
          <input
            type="radio"
            role='button'
            checked={selectedMethod === 'MB'}
            disabled={isValidating}
            onChange={() => handleSelectMethod('MB')}
          />
          <div className='ms-2 inline-flex-start w-100'>
            <PaymentLogo type={"MB"} imgStyle={{ maxHeight: "calc(100% - 6px)" }}/>
            <span className='ms-2'>Referência Multibanco </span>
          </div>
        </label>
        {renderPaymentState(PaymentActive.MB)}
      </div>
    ));
  };

  const renderMBWay = () => {
    const renderPhoneInput = () => {
      return (selectedMethod === 'MBWAY' && (
        <div className='ms-5 mb-4'>
          <label>
            <InputField type='text'
              title='Número de telefone (associado ao MB WAY) para pagamento'
              value={phoneNumber}
              styleInput="input-medium-default"
              valueFormat='phoneNumber' minLength={9} maxLength={9}
              onChange={handlePhoneChange}
              onFocus={handleScrollDown}
              onClick={() => {!isButtonDisabled && handleContinuePayment()}}
              prefix={prefix}
              enterKeyPress={true}
              placeholder='Digite o número associado ao MB WAY' />
          </label>
        </div>
      ));
    };
    return (props.methods?.includes("MBWAY") && (
      <div className="method mt-3 w-100" role='button'>
        <label className='inline-flex-start w-100' role='button'>
          <input
            type="radio"
            role='button'
            checked={selectedMethod === 'MBWAY'}
            disabled={isValidating}
            onChange={() => handleSelectMethod('MBWAY')}
          />
          <div className='ms-2 inline-flex-start w-100'>
            <PaymentLogo type={"MBWAY"}/>
            <span className='ms-2'>MB WAY </span>
          </div>
        </label>
        {renderPhoneInput()}
        {renderPaymentState(PaymentActive.MBWAY)}
      </div>
    ));
  };

  const renderCC = () => {
    return (props.methods?.includes("CC") && (
      <div className="method mt-3 w-100" role='button'>
        <label className='inline-flex-start w-100' role='button'>
          <input
            type="radio"
            role='button'
            checked={selectedMethod === 'CC'}
            disabled={isValidating}
            onChange={() => handleSelectMethod('CC')}
          />
          <div className='ms-2 inline-flex-start w-100'>
            <CardIcon />
            <span className='mx-2'> Cartão </span>
            <PaymentLogo type={"VISA"}/>
            <PaymentLogo type={"MASTER_CARD"} className={"ms-1"}/>
            <PaymentLogo type={"MAESTRO"} className={"ms-1"}/>
          </div>
        </label>
        {renderPaymentState(PaymentActive.CC)}
      </div>
    ));
  };

  const renderPaymentState = (active) => {
    return active !== payment?.details?.type ? null : <PaymentStateDetails payment={payment} selectedMethod={selectedMethod} />
  };

  const renderButtonLabel = () => {
    // If the payment is pending and the method is Credit Card (CC)
    if (payment.state === PaymentState.PENDING && selectedMethod === PaymentMethod.CC) {
      if (payment?.details?.type === PaymentActive.CC && (payment?.urlParams?.brand || payment.urlParams?.pan)) {
        return Labels.payment.button.newcc;
      }
      return Labels.payment.button.cc;
    }
    if (selectedMethod === PaymentMethod.MBWAY) {
      return Labels.payment.button.mbway;
    }
    if (selectedMethod === PaymentMethod.MB) {
      return Labels.payment.button.mb;
    }
    if (selectedMethod === PaymentMethod.CC) {
      return Labels.payment.button.cc;
    }
    return Labels.payment.button.continue;
  };

  const renderSubmitButton = () => {
    const className = classNames("cta-button-primary w-75 mt-3", { 
      "is-disabled": isButtonDisabled
    });
    return (
      <div className='flex-center w-100 mt-5'>
        <button
          className={className}
          type="button"
          onClick={handleContinuePayment}
          disabled={isButtonDisabled}
        >
          {renderButtonLabel()}
        </button>
      </div>
    );
  }

  return (
    <div className="payment-methods w-100 align-left">
      <h3>Pagamento</h3>
      {payment?.expirationDate && <p className='medium-regular-black'>{Labels.payment.subtitle(payment?.expirationDate)}</p>}

      <div className="grey-box-container w-100 mt-2 px-3 pt-2 pt-lg-3">

        <div className="methods-list">
          {renderMB()}
          {renderMBWay()}
          {renderCC()}
        </div>
      </div>
      {/* {renderPaymentState()} */}
      {renderSubmitButton()}
    </div>
  );
};

export default PaymentMethods;

export const CardIcon = () => {
  return (
    <div className="logo-box flex-center">
      <div className="credit-card">
        <div className="band"></div>
        <div className="number-code">xxxx xxxx</div>
        <div className="code-ref"></div>
      </div>
    </div>
  );
}

const handleScrollDown = () => {
  setTimeout(() => {      
    // Scroll the window to the bottom
    window.scrollTo({
      top: document.documentElement.scrollHeight, // Scroll to the bottom of the document
    });
  
    // Scroll a specific element with the id 'paymentPageContainer' to the bottom
    const el = document.getElementById("scrollContainer");
    if (el) {
      el.scrollTo({ top: el.clientHeight, behavior: "smooth" });
    }
  }, 100);
};