import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ initialTimeInSeconds }) => {
  // State to store the remaining time in seconds
  const [timeLeft, setTimeLeft] = useState(initialTimeInSeconds);

  useEffect(() => { setTimeLeft(initialTimeInSeconds);}, [initialTimeInSeconds]);
  useEffect(() => {
    // If the time reaches zero, no need to continue updating
    if (timeLeft <= 0) return;

    // Set an interval to update the countdown every second
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        // If the time reaches zero, clear the interval and stop the countdown
        if (prevTime <= 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1; // Decrease the time by 1 second
      });
    }, 1000); // 1000 milliseconds = 1 second

    // Cleanup the interval when the component is unmounted or when the time reaches zero
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  // Convert the remaining time into minutes and seconds
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  // Format the time to always show two digits for minutes and seconds
  const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  return (
    <div>
      {/* Display the formatted countdown time */}
      <div>{timeLeft >0 && formattedTime}</div>
    </div>
  );
};

export default CountdownTimer;
