import React from 'react';
import classNames from "classnames";
import { PAYMENT } from "../../assets/images/images";
import "./Payment.scss";

export const PaymentLogo = ({ type, className, imgStyle }) => {
  const icon =
    type === "VISA"
      ? PAYMENT.visa
      : type === "MASTER_CARD"
        ? PAYMENT.mastercard
        : type === "MB"
          ? PAYMENT.mb
          : type === "MAESTRO"
            ? PAYMENT.maestro
            : PAYMENT.mbway;

  const title = type === "VISA"
  ? "VISA"
  : type === "MASTER_CARD"
    ? "MASTERCARD"
    : type === "MB"
      ? "MULTIBANCO"
      : type === "MAESTRO"
        ? "MAESTRO"
        : "MB WAY";
  return (
    <div className={classNames("logo-box flex-center", { [className]: className})} title={title}>
      <img src={icon} alt="payment_logo" style={imgStyle}/>
    </div>
  );
}