/** @type {AppConfig} */
const appConfig = {
  // /** @type {AppEnvironment} */
  development: {
    DOMAIN: "keymaster.pt",
    APP_URL: new URL("https://www.keymaster.pt").href,
    // BASE_CAL_URL: "https://comdepcomexp.qa.keymaster.pt/cal",
    // BASE_CAL_URL: "http://comdepcomexp.qa.keymaster.pt:8080/cal",
    BASE_CAL_URL: "http://10.10.15.16:8080/cal",
    // BASE_URL: "http://comdepcomexp.qa.keymaster.pt:8080/kss",
    BASE_URL: "https://checkpoint.qa.keymaster.pt/kss",
    BASE_CLUSTER_URL: 'http://checkpoint.qa.keymaster.pt:8080',

    // BASE_URL: 'http://clickstop.qa.keymaster.pt:8080/kss',
    // BASE_CLUSTER_URL: 'http://clickstop.qa.keymaster.pt:8080',
    LANDING_URL: new URL("https://asuaempresa.qa.keymaster.pt/clientwelcome/").href,

    // BASE_URL: 'http://teste27092023.qa.keymaster.pt:8080/kss',
    // BASE_CLUSTER_URL: 'http://teste27092023.qa.keymaster.pt:8080',
  },
  /** @type {AppEnvironment} */
  test: {
    DOMAIN: "keymaster.pt",
    APP_URL: new URL("https://www.keymaster.pt").href,
    BASE_CAL_URL: "http://checkpoint.qa.keymaster.pt:8080/cal",
    BASE_URL: "http://checkpoint.qa.keymaster.pt:8080/kss",
    BASE_CLUSTER_URL: '',
    LANDING_URL: new URL("https://asuaempresa.qa.keymaster.pt/clientwelcome/").href,
  },
  /** @type {AppEnvironment} */
  production: {
    DOMAIN: "keymaster.pt",
    APP_URL: new URL("https://www.keymaster.pt").href,
    BASE_CAL_URL:  "/cal",
    BASE_URL: "/kss",
    BASE_CLUSTER_URL: '',
    LANDING_URL: new URL("https://asuaempresa.keymaster.pt/clientwelcome/").href,
  },
};

export default appConfig[process.env.NODE_ENV || 'development'];