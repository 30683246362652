// =======================
// PAYMENT
// =======================
const PaymentMethod = Object.freeze({
  MB: "MB",
  MBWAY: "MBWAY",
  CC: "CC",
});

const PaymentActive = Object.freeze({
  MB: "MbPayment",
  MBWAY: "MbWayPayment",
  CC: "CcPayment",
});

const PaymentState = Object.freeze({
  PENDING: "PENDING",
  PAYED: "PAYED",  // Explicitly defining the value for "PAYED"
  EXPIRED: "EXPIRED",
  CC_CANCEL: "CC_CANCEL",  // Explicitly defining the value for "CC_CANCEL"
  CC_ERROR: "CC_ERROR",    // Explicitly defining the value for "CC_ERROR"
  MBWAY_REJECTED: "MBWAY_REJECTED",  // Explicitly defining the value for "MBWAY_REJECTED"
  MBWAY_DECLINED: "MBWAY_DECLINED",  // Explicitly defining the value for "MBWAY_DECLINED"
});

const PaymentMethodLabel = Object.freeze({
  MB: "Multibanco",
  MBWAY: "MB WAY",
  CC: "Cartão",
});

const PaymentStateLabel = Object.freeze({
  PENDING: "Pendente",
  PAYED: "Pago",
  EXPIRED: "Expirado",         
  CANCEL: "Cancelado",         
  CC_CANCEL: "Cancelado",  
  CC_ERROR: "Erro",        
  MBWAY_REJECTED: "Rejeitado (MBWay)",  
  MBWAY_DECLINED: "Recusado (MBWay)" 
});

// Export groups
export { PaymentMethod, PaymentState, PaymentMethodLabel, PaymentStateLabel, PaymentActive }; 