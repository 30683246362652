// React Imports
import React from 'react';

import 'components/button/menuButton.style.scss';

class MenuButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: this.props.icon,
      title: this.props.title,
      path: this.props.path,
      disabled: this.props.disabled
    };
  }

  render() {
    return (
      <div className={`button-menu-container ${this.state.disabled ? "is-disabled" : ""}`} onClick={this.props.onClick}>
        <div className="icon-type-container me-3">
          <img src={this.state.icon} alt="" />
        </div>
        <div className="text-menu-button-container">
          <p className="text-menu-button text-ellipsis-2-lines">{this.state.title}</p>
        </div>
      </div>
    );
  }
}

export default MenuButton;
