import React, { Fragment, useState } from 'react';
import Helper from '../../../core/helper/helper';

const CardItemInformation = (props) => {
  const { data: { id, title, subtitle }, styleClass } = props;
  const [randomTitle] = useState(
    Helper.newRandomString(
      Helper.printValue(title)?.length - Helper.printValue(title)?.slice(" ")[1]?.length
    )
  );
  const [randomSubtitle] = useState(
    Helper.newRandomString(
      Helper.printValue(subtitle)?.length - Helper.printValue(subtitle)?.slice(" ")[1]?.length
    )
  );

  const renderValue = (value, random) => {
    return (Helper.isLiteVersion() && !id?.includes("int")) ? (
      <Fragment>
        <span>
          {(() => {
            const processedValue = Helper.printValue(value);
            if (processedValue.includes(" ") || processedValue.includes("-")) {
              return processedValue.split(" ")[0].split("-")[0];
            } else {
              return processedValue.slice(0, 2);
            }
          })()}
        </span>
        <span className='pixelized'>{random}</span>
      </Fragment>
    ) : (
      Helper.printValue(value)
    );
  };
  
  return (
    <div className={`service-equipment-container ${styleClass || ''}`}>
      <h3 className='text-truncate w-100'>{renderValue(Helper.formatTitleIfBirthday(title,true), randomTitle)}</h3>
      <div className='service-equipment-id text-truncate'>{renderValue(Helper.formatTitleIfBirthday(subtitle,false), randomSubtitle)}</div>
    </div>
  );
}

export default CardItemInformation;